import { polishDate } from 'common/formatters/date';
import { abbreviateModelName, modelName } from 'common/models';
import _ from 'lodash';
import React from 'react';
export function Car(props) {
    return React.createElement("span", null, _.chain([polishDate(props.car.handoverDate), abbreviateModelName(modelName(props.car.modelId)), props.car.vin])
        .filter(_.identity)
        .join(', ')
        .value());
}
