// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react .screen.container {
  margin-top: 40px;
}
#react #tooltip-container {
  position: relative;
}
#react a.link {
  color: #5A91BB;
}
#react a.link:hover {
  text-decoration: underline;
}
#react .assistant-timeline {
  margin-left: -15px;
  margin-right: -15px;
}
#react .assistant-timeline .filter-controls {
  margin-bottom: 5px;
}
#react .assistant-timeline .timeline-content {
  height: calc(100vh - 350px);
}
#react .assistant-timeline .timeline-content table {
  overflow-y: scroll;
}
#react .assistant-timeline .timestamp {
  vertical-align: middle;
}
#react .assistant-timeline .event-type {
  vertical-align: middle;
  text-align: center;
}
#react .assistant-timeline .well {
  margin-bottom: 0;
  min-height: 40px;
}
#react .assistant-timeline .well-sm {
  border-radius: 3px;
}
#react .assistant-timeline table.table {
  margin-bottom: 0;
}
#react .assistant-timeline table.table tr td:first-child {
  padding-left: 0;
}
#react .assistant-timeline table.table tr td:last-child {
  padding-right: 0;
}
#react .assistant-timeline td {
  border-top: none;
}
#react .assistant-timeline button.link-button {
  border: none;
  background: none;
  color: #5A91BB;
}
#react .assistant-timeline button.link-button:hover {
  text-decoration: underline;
}
#react .assistant-timeline .event-description-block {
  word-break: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/root.less","webpack://./src/assistant-timeline/AssistantTimeline.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;ACCF;ADEA;EACE,kBAAA;ACAF;ADKA;EACE,cAAA;ACHF;ADIE;EACE,0BAAA;ACFJ;AATA;EACE,kBAAA;EACA,mBAAA;AAWF;AAbA;EAKI,kBAAA;AAWJ;AAhBA;EASI,2BAAA;AAUJ;AAnBA;EAYM,kBAAA;AAUN;AAtBA;EAiBI,sBAAA;AAQJ;AAzBA;EAqBI,sBAAA;EAAwB,kBAAA;AAQ5B;AA7BA;EAyBI,gBAAA;EACA,gBAAA;AAOJ;AAjCA;EA8BI,kBAAA;AAMJ;AApCA;EAkCI,gBAAA;AAKJ;AAvCA;EAqCM,eAAA;AAKN;AA1CA;EAyCM,gBAAA;AAIN;AA7CA;EA8CI,gBAAA;AAEJ;AAhDA;EAkDI,YAAA;EACA,gBAAA;EACA,cAAA;AACJ;AACI;EACE,0BAAA;AACN;AAxDA;EA4DI,sBAAA;AADJ","sourcesContent":[".screen.container {\n  margin-top: 40px;\n}\n\n#tooltip-container {\n  position: relative;\n}\n\n@btn-confirm-bg: #5A91BB;\n\na.link {\n  color: @btn-confirm-bg;\n  &:hover {\n    text-decoration: underline;\n  }\n}","@import \"../root\";\n\n.assistant-timeline {\n  margin-left: -15px;\n  margin-right: -15px;\n\n  .filter-controls {\n    margin-bottom: 5px;\n  }\n\n  .timeline-content {\n    height: calc(100vh - 350px);\n\n    table {\n      overflow-y: scroll;\n    }\n  }\n\n  .timestamp {\n    vertical-align: middle\n  }\n\n  .event-type {\n    vertical-align: middle; text-align: center;\n  }\n\n  .well {\n    margin-bottom: 0;\n    min-height: 40px;\n  }\n\n  .well-sm {\n    border-radius: 3px;\n  }\n\n  table.table {\n    margin-bottom: 0;\n\n    tr td:first-child {\n      padding-left: 0;\n    }\n\n    tr td:last-child {\n      padding-right: 0;\n    }\n  }\n\n  td {\n    border-top: none;\n  }\n\n  button.link-button {\n    border: none;\n    background: none;\n    color: @btn-confirm-bg;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n\n  .event-description-block {\n    word-break: break-word;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
