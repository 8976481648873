import { AbstractTask } from './abstractTask.component';
import { shortPartyDescription } from '../../../common/party';
import { modelName } from '../../../common/models';
export function OrderToSettleTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Rozlicz rabat VCP',
        description: "".concat(task.representativeName, " - ").concat(shortPartyDescription(task.party), ", ").concat(modelName(task.model), ", ").concat(task.doin),
        redirectUrl: "#/orders/".concat(task.orderId, "/concessions")
    });
}
