import { AbstractTask } from './abstractTask.component';
export function OrderToWithdrawTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Wycofaj zamówienie',
        description: task.dealerOrder,
        redirectUrl: "#/orders/".concat(task.orderId)
    });
}
