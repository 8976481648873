import React from 'react';
export function CarSalePotential(props) {
    var _a;
    return React.createElement("div", { className: "form-horizontal col-lg-12" },
        React.createElement("div", { className: "form-inline" },
            React.createElement("div", { className: "form-group col-lg-12" },
                React.createElement("label", { id: "carsSalePotentialLabel", className: "control-label col-lg-3" }, "Liczba samochod\u00F3w"),
                React.createElement("div", { "aria-labelledby": "carsSalePotentialLabel", className: "col-lg-6" },
                    React.createElement("span", { className: "form-control-static" }, (_a = props.application) === null || _a === void 0 ? void 0 : _a.carsSalePotential)))));
}
