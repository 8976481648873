import { AssistantTimeline } from 'assistant-timeline/AssistantTimeline.component';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import { Dashboard } from 'dashboard/Dashboard.component';
import { useSalesList } from 'employees/sales';
import { ScheduledContacts } from 'employees/scheduled-contacts/ScheduledContacts.component';
import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import './Employees.less';
export var Employees = withCancellation(function () {
    var salesList = useSalesList();
    var _a = useState(undefined), selectedSales = _a[0], setSelectedSales = _a[1];
    var _b = useState('DASHBOARD'), section = _b[0], setSection = _b[1];
    var salesSelect = useSelect();
    useEffect(function () {
        salesList.load()
            .then(function (response) {
            salesSelect.setOptions(response);
        });
    }, []);
    function show() {
        setSelectedSales(salesSelect.selectedValue);
    }
    return (React.createElement("article", { className: "employees" },
        React.createElement("section", { className: "form-inline row sales-selection-controls" },
            React.createElement("div", { className: "form-group" },
                React.createElement("label", { htmlFor: "sales-select", className: "control-label" }, "Sprzedawca"),
                React.createElement("select", { id: "sales-select", onChange: salesSelect.setSelectedValue, value: salesSelect.selectedValue, className: "form-control" },
                    React.createElement("option", { value: "", disabled: true }, "Wybierz"),
                    salesSelect.options.map(function (sales) {
                        return React.createElement("option", { value: sales.login, key: sales.login }, "".concat(sales.name, " (").concat(sales.login, ")"));
                    }))),
            React.createElement("button", { onClick: show, className: "btn btn-sm btn-confirm", disabled: salesSelect.selectedValue === '' }, "Poka\u017C")),
        React.createElement("section", { className: "employee-content" },
            React.createElement("div", { className: "employees-sections-selector" },
                React.createElement(ToggleButtonGroup, { type: "radio", name: "employees-sections-selector", justified: true, value: section, onChange: setSection },
                    React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "dashboard-selector", value: "DASHBOARD" }, "Plan dnia"),
                    React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "history-selector", value: "TIMELINE" }, "Historia"),
                    React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "scheduled-contacts-selector", value: "SCHEDULED_CONTACTS" }, "Zaplanowane"))),
            selectedSales ?
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: section === 'DASHBOARD' ? '' : 'hidden' },
                        React.createElement(Dashboard, { sales: selectedSales })),
                    React.createElement("div", { className: section === 'TIMELINE' ? '' : 'hidden' },
                        React.createElement(AssistantTimeline, { assistantId: selectedSales })),
                    React.createElement("div", { className: section === 'SCHEDULED_CONTACTS' ? '' : 'hidden' },
                        React.createElement(ScheduledContacts, { assistantId: selectedSales }))) : null)));
});
function useSelect() {
    var _a = useState([]), options = _a[0], setOptions = _a[1];
    var _b = useState(''), selectedValue = _b[0], setSelectedValue = _b[1];
    return {
        options: options,
        setOptions: setOptions,
        selectedValue: selectedValue,
        setSelectedValue: function (event) { return setSelectedValue(event.target.value); }
    };
}
