export function fundingName(funding) {
    if (!funding) {
        return '';
    }
    switch (funding.fundingForm.id) {
        case 'DETAILED_LEASING':
            return "".concat(funding.fundingForm.name, " - ").concat(funding.leasingProvider.name);
        case 'CFM_FUNDING':
            return "".concat(funding.fundingForm.name, " - ").concat(funding.cfmProvider.name);
        default:
            return funding.fundingForm.name;
    }
}
