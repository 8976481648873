var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ConcessionApplicationEvent } from 'assistant-timeline/events/ConcessionApplicationEvent.component';
import { ContactEvent } from 'assistant-timeline/events/ContactEvent.component';
import { eventTypeDescription, timelineTimestamp } from 'assistant-timeline/events/events';
import { ExtraSaleDiscountApplicationEvent } from 'assistant-timeline/events/ExtraSaleDiscountApplicationEvent.component';
import { HandoverEvent } from 'assistant-timeline/events/HandoverEvent.component';
import { OfferEvent } from 'assistant-timeline/events/OfferEvent.component';
import { OrderEvent } from 'assistant-timeline/events/OrderEvent.component';
import { OrderWithdrawalEvent } from 'assistant-timeline/events/OrderWithdrawalEvent.component';
import { UnknownEvent } from 'assistant-timeline/events/UnknownEvent.component';
import { useTimeline } from 'assistant-timeline/timeline';
import { formatDate } from 'common/formatters/date';
import { useProgressTracker } from 'common/hooks/useProgressTracker';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import * as _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import './AssistantTimeline.less';
// #/employees
export var AssistantTimeline = withCancellation(function (props) {
    var timeline = useTimeline();
    var _a = useState('ALL'), timelineFilter = _a[0], setTimelineFilter = _a[1];
    var _b = useState([]), rawEvents = _b[0], setRawEvents = _b[1];
    var _c = useState([]), filteredEvents = _c[0], setFilteredEvents = _c[1];
    var _d = useState(undefined), oldestEventsDate = _d[0], setOldestEventsDate = _d[1];
    var showMoreTracker = useProgressTracker();
    useEffect(function () {
        resetTimeRange();
        setRawEvents([]);
    }, [props.assistantId]);
    useEffect(function () {
        if (oldestEventsDate) {
            showMoreEvents();
        }
    }, [oldestEventsDate]);
    function moveTimeRangeBackward() {
        setOldestEventsDate(function (prev) { return moment(prev).subtract(1, 'month').subtract(1, 'day'); });
    }
    function resetTimeRange() {
        setOldestEventsDate(moment());
    }
    function loadTimeline(from, to) {
        if (props.assistantId) {
            return timeline.loadForAssistant(props.assistantId, from, to);
        }
        else {
            return timeline.loadForCurrentAssistant(from, to);
        }
    }
    function hideRejectedSpecialOffers(timelineEvents) {
        function isSpecialOffer(event) {
            return event.payload.number ? event.payload.number.startsWith('OS/') : false;
        }
        return timelineEvents.filter(function (event) {
            return !(isSpecialOffer(event) && (event.payload.status === 'REJECTED' || event.payload.status === 'DECLINED'));
        });
    }
    function showMoreEvents() {
        var from = formatDate(moment(oldestEventsDate).subtract(1, 'month'));
        var to = formatDate(moment(oldestEventsDate));
        showMoreTracker.withTracking(loadTimeline(from, to))
            .then(function (response) { return setRawEvents(function (prevRawEvents) { return _.orderBy(__spreadArray(__spreadArray([], prevRawEvents, true), hideRejectedSpecialOffers(response.data), true), 'timestamp', 'desc'); }); })
            .catch(function () { });
    }
    useEffect(function () {
        filter();
        function filter() {
            switch (timelineFilter) {
                case 'OFFER':
                    setFilteredEvents(rawEvents.filter(function (event) { return _.includes(['OFFER', 'SUGGESTED_OFFER'], event.eventType); }));
                    break;
                case 'CAR':
                    setFilteredEvents(rawEvents.filter(function (event) { return _.includes(['ORDER', 'HANDOVER'], event.eventType); }));
                    break;
                case 'DRS_DISCOUNT':
                    setFilteredEvents(rawEvents.filter(function (event) { return _.includes(['EXTRA_SALE_DISCOUNT_APPLICATION'], event.eventType); }));
                    break;
                case 'VCP_DISCOUNT':
                    setFilteredEvents(rawEvents.filter(function (event) { return _.includes(['CONCESSION_APPLICATION'], event.eventType); }));
                    break;
                case 'CONTACT':
                    setFilteredEvents(rawEvents.filter(function (event) { return _.includes(['CALL', 'MEETING', 'OTHER'], event.eventType); }));
                    break;
                default:
                    setFilteredEvents(rawEvents);
            }
        }
    }, [rawEvents, timelineFilter]);
    function renderEvent(event) {
        return (React.createElement("tr", { key: "".concat(event.eventType, "-").concat(event.id), className: "event-record", "data-testid": 'event-record-' + event.id },
            React.createElement("td", { className: "timestamp", "data-testid": "timestamp-field" },
                React.createElement("span", null, timelineTimestamp(event.timestamp))),
            React.createElement("td", { "data-testid": "event-record__type", className: "event-type" }, eventTypeDescription(event.eventType, event.payload.location, event.payload.number ? event.payload.number.startsWith('OS/') : false)),
            React.createElement("td", { className: "event-description-block", "data-testid": "event-record__content" }, function () {
                var component = eventComponents[event.eventType];
                return component ? component(event) : React.createElement(UnknownEvent, { event: event });
            }())));
    }
    return (React.createElement("article", { className: "assistant-timeline", "data-testid": "assistant-timeline" },
        React.createElement(ToggleButtonGroup, { className: "filter-controls", type: "radio", name: "timeline-filter", justified: true, value: timelineFilter, onChange: setTimelineFilter },
            React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "all-timeline-filter", value: "ALL" }, "Wszystkie"),
            React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "offers-timeline-filter", value: "OFFER" }, "Oferty"),
            React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "car-timeline-filter", value: "CAR" }, "Samochody"),
            React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "drs-discount-timeline-filter", value: "DRS_DISCOUNT" }, "Rabaty DRS"),
            React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "vcp-discount-timeline-filter", value: "VCP_DISCOUNT" }, "Rabaty VCP"),
            React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "contact-timeline-filter", value: "CONTACT" }, "Zdarzenia")),
        React.createElement("div", { className: "timeline-content" },
            React.createElement("table", { className: "table" },
                React.createElement("colgroup", null,
                    React.createElement("col", { className: "col-lg-2" }),
                    React.createElement("col", { className: "col-lg-1" }),
                    React.createElement("col", { className: "col-lg-9" })),
                React.createElement("tbody", null, filteredEvents.map(function (event) { return renderEvent(event); })),
                React.createElement("tfoot", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: 4, className: "text-center" },
                            React.createElement("button", { onClick: moveTimeRangeBackward, disabled: showMoreTracker.loading, className: "link-button", "data-testid": "show-more-events" }, "Wi\u0119cej"))))))));
});
var eventComponents = {
    SUGGESTED_OFFER: function (event) { return (React.createElement(OfferEvent, { key: event.id, event: event })); },
    OFFER: function (event) { return (React.createElement(OfferEvent, { key: event.id, event: event })); },
    TEST_DRIVE: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    DEMO_CAR_HANDOVER: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    DEMO_CAR_RETURN: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    CALL: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    MEETING: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    INTERNET: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    OTHER: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    NEW_CLIENT: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    RETURNING_CLIENT: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    SERVICE_VISIT: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    SMS_CAMPAIGN: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    PHONE_NUMBER_CAMPAIGN: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    EMAIL_CAMPAIGN: function (event) { return (React.createElement(ContactEvent, { key: event.id, event: event })); },
    ORDER: function (event) { return (React.createElement(OrderEvent, { key: event.id, event: event })); },
    HANDOVER: function (event) { return (React.createElement(HandoverEvent, { key: event.id, event: event })); },
    ORDER_WITHDRAWAL: function (event) { return (React.createElement(OrderWithdrawalEvent, { key: event.id, event: event })); },
    CONCESSION_APPLICATION: function (event) { return (React.createElement(ConcessionApplicationEvent, { key: event.id, event: event })); },
    EXTRA_SALE_DISCOUNT_APPLICATION: function (event) { return (React.createElement(ExtraSaleDiscountApplicationEvent, { key: event.id, event: event })); },
};
