// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react .screen.container {
  margin-top: 40px;
}
#react #tooltip-container {
  position: relative;
}
#react a.link {
  color: #5A91BB;
}
#react a.link:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/root.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EACE,kBAAA;AAAF;AAKA;EACE,cAAA;AAHF;AAIE;EACE,0BAAA;AAFJ","sourcesContent":[".screen.container {\n  margin-top: 40px;\n}\n\n#tooltip-container {\n  position: relative;\n}\n\n@btn-confirm-bg: #5A91BB;\n\na.link {\n  color: @btn-confirm-bg;\n  &:hover {\n    text-decoration: underline;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
