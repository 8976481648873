// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react .employees .sales-selection-controls {
  margin-bottom: 10px;
}
#react .employees .sales-selection-controls label {
  margin-right: 4px;
}
#react .employees .sales-selection-controls button.btn-confirm {
  margin-left: 15px;
}
#react .employees .employees-sections-selector {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
}
#react .employees .tasks .tasks__list {
  height: calc(100vh - 340px);
}
#react .employees .tabs-container .tasks .tasks__list {
  height: calc(100vh - 400px);
}
#react .employees .day-schedule .calendar .fc-scroller.fc-time-grid-container {
  height: calc(100vh - 398px) !important;
}
#react .employees .week-calendar .calendar .fc-scroller.fc-time-grid-container {
  height: calc(100vh - 380px) !important;
}
`, "",{"version":3,"sources":["webpack://./src/employees/Employees.less"],"names":[],"mappings":"AAAA;EAEI,mBAAA;AAAJ;AAFA;EAKM,iBAAA;AAAN;AALA;EAQM,iBAAA;AAAN;AARA;EAaI,kBAAA;EACA,mBAAA;EACA,mBAAA;AAFJ;AAbA;EAoBM,2BAAA;AAJN;AAhBA;EA0BM,2BAAA;AAPN;AAnBA;EAiCQ,sCAAA;AAXR;AAtBA;EAyCQ,sCAAA;AAhBR","sourcesContent":[".employees {\n  .sales-selection-controls {\n    margin-bottom: 10px;\n\n    label {\n      margin-right: 4px;\n    }\n    button.btn-confirm {\n      margin-left: 15px;\n    }\n  }\n\n  .employees-sections-selector {\n    margin-left: -15px;\n    margin-right: -15px;\n    margin-bottom: 15px;\n  }\n\n  .tasks {\n    .tasks__list {\n      height: calc(100vh - 340px);\n    }\n  }\n\n  .tabs-container .tasks {\n    .tasks__list {\n      height: calc(100vh - 400px);\n    }\n  }\n\n  .day-schedule {\n    .calendar {\n      .fc-scroller.fc-time-grid-container {\n        height: calc(100vh - 398px) !important;\n      }\n    }\n  }\n\n  .week-calendar {\n    .calendar {\n      .fc-scroller.fc-time-grid-container {\n        height: calc(100vh - 380px) !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
