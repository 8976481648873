export function partyType(partyType) {
    switch (partyType) {
        case 'INDIVIDUAL':
            return 'Klient indywidualny';
        case 'SELF_EMPLOYED':
            return 'Działalność';
        case 'COMPANY':
            return 'Spółka';
        default:
            return '';
    }
    ;
}
