var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
export function useTasks() {
    var _a = useState([]), rawTasks = _a[0], setRawTasks = _a[1];
    var _b = useState([]), displayedTasks = _b[0], setDisplayedTasks = _b[1];
    var _c = useState([]), dayCalendarTasks = _c[0], setDayCalendarTasks = _c[1];
    var _d = useState([]), weekCalendarTasks = _d[0], setWeekCalendarTasks = _d[1];
    var _e = useState([]), activeCalendarTasks = _e[0], setActiveCalendarTasks = _e[1];
    var _f = useState(0), todayTasksCount = _f[0], setTodayTasksCount = _f[1];
    var _g = useState(0), overdueTasksCount = _g[0], setOverdueTasksCount = _g[1];
    var _h = useState(''), phraseFilter = _h[0], setPhraseFilter = _h[1];
    var _j = useState('ALL'), categoryFilter = _j[0], setCategoryFilter = _j[1];
    var _k = useState({ from: '1900-01-01', to: moment().toISOString() }), dateFilter = _k[0], setDateFilter = _k[1];
    var filters = {
        phrase: phraseFilter,
        setPhrase: setPhraseFilter,
        category: categoryFilter,
        setCategory: setCategoryFilter,
        date: dateFilter,
        setDate: setDateFilter
    };
    useEffect(function () {
        setTodayTasksCount(todayTasksCount());
        setOverdueTasksCount(overdueTasksCount());
        function todayTasksCount() {
            function isScheduledOnToday(task) {
                return task.from && moment().isSame(task.from, 'day');
            }
            return _.filter(rawTasks, isScheduledOnToday).length;
        }
        function overdueTasksCount() {
            function isOverdue(task) {
                return !task.from || moment().isAfter(task.from, 'day');
            }
            return _.filter(rawTasks, isOverdue).length;
        }
    }, [rawTasks]);
    useEffect(function () {
        setDisplayedTasks(getAll());
        setWeekCalendarTasks(getWeekCalendarTasks());
        setDayCalendarTasks(getDayCalendarTasks());
    }, [rawTasks]);
    useEffect(function () {
        setDisplayedTasks(getAll());
    }, [rawTasks, filters.phrase, filters.category, filters.date]);
    useEffect(function () {
        setDayCalendarTasks(getDayCalendarTasks());
    }, [rawTasks, filters.date, activeCalendarTasks]);
    var currentWeek = moment(filters.date.to).isoWeek();
    useEffect(function () {
        setWeekCalendarTasks(getWeekCalendarTasks());
    }, [rawTasks, currentWeek, activeCalendarTasks]);
    useEffect(function () {
        setActiveCalendarTasks(_.chain(rawTasks)
            .filter(function (task) { return !filters.phrase || getPhraseFilter()(task); })
            .map(function (task) { return task.id; })
            .value());
    }, [rawTasks, filters.phrase]);
    function getDayCalendarTasks() {
        return getAll({ category: false, phrase: false, date: false })
            .filter(function (task) { return moment(filters.date.to).isBetween(moment(task.from), moment(task.to), 'day', '[]'); })
            .filter(function (task) { return _.includes(scheduledTasksIds, task.type); })
            .map(mapTaskToEvent);
    }
    function getWeekCalendarTasks() {
        return getAll({ category: false, phrase: false, date: false })
            .filter(function (task) { return moment(filters.date.to).isBetween(moment(task.from), moment(task.to), 'isoWeek', '[]'); })
            .filter(function (task) { return _.includes(scheduledTasksIds, task.type); })
            .map(mapTaskToEvent);
    }
    function mapTaskToEvent(task) {
        return {
            id: task.id,
            start: task.from,
            end: task.to,
            extendedProps: __assign(__assign({}, task), { inactive: !activeCalendarTasks.includes(task.id) })
        };
    }
    function getAll(filters) {
        if (filters === void 0) { filters = {
            category: true,
            phrase: true,
            date: true
        }; }
        return _.chain(rawTasks)
            .filter(function (task) { return task['from'] ? (!filters.date || getDateFilter()(task['from'])) : true; })
            .filter(function (task) { return !filters.category || getCategoryFilter()(task); })
            .filter(function (task) { return !filters.phrase || getPhraseFilter()(task); })
            .sortBy(function (task) {
            var taskTypeOrder = typeOrder.indexOf(task.type);
            return taskTypeOrder !== -1
                ? taskTypeOrder * 1000000000 +
                    (task.applicationSubmissionTimestamp
                        ? moment(task.applicationSubmissionTimestamp).unix()
                        : 0)
                : task.from
                    ? moment(task.from).unix() * 100
                    : 999999999999;
        })
            .value();
    }
    function getCategoryFilter() {
        return categoryFilters[filters.category] || (function () { return false; });
    }
    function getPhraseFilter() {
        return textFilterBy(filters.phrase.toLowerCase());
    }
    function getDateFilter() {
        return function (dateString) {
            if (!filters.date.from || !filters.date.to) {
                return true;
            }
            var from = moment(filters.date.from);
            var to = moment(filters.date.to);
            var date = moment(dateString);
            return (date.isSameOrAfter(from, 'day') &&
                date.isSameOrBefore(to, 'day'));
        };
    }
    function textFilterBy(filterPhrase) {
        return (_.trim(filterPhrase).length > 0) ?
            function (task) { return task.textIndex && task.textIndex.indexOf(filterPhrase) !== -1; } :
            function () { return true; };
    }
    return {
        setRawTasks: setRawTasks,
        dayCalendarTasks: dayCalendarTasks,
        weekCalendarTasks: weekCalendarTasks,
        displayedTasks: displayedTasks,
        todayTasksCount: todayTasksCount,
        overdueTasksCount: overdueTasksCount,
        filters: filters
    };
}
var categoryFilters = {
    ALL: function () {
        return true;
    },
    LEADS: function (task) {
        return _.includes([
            'LEADS',
            'SCHEDULED_RETURNING_CLIENT_CALLS',
            'USED_CARS_LEAD_TO_PICK_UP',
            'LEADS_ASSISTANT_ASSIGNMENT',
            'CLIENTS_ASSISTANT_ASSIGNMENT',
            'CLIENTS_WITH_NOT_ACTIVE_ASSISTANT',
        ], task.type);
    },
    CONSENTS: function (task) {
        return _.includes(['CLIENTS_WITHOUT_CONSENTS', 'CONSENT_WITHDRAWAL_APPLICATIONS'], task.type);
    },
    SCHEDULED: function (task) {
        return _.includes([
            'PENDING_COLD_CALLINGS',
            'SCHEDULED_MEETINGS',
            'SCHEDULED_CALLS',
            'SCHEDULED_OTHER_CONTACTS',
            'SCHEDULED_TEST_DRIVES',
            'SCHEDULED_DEMO_CAR_HANDOVERS',
            'SCHEDULED_DEMO_CAR_RETURNS',
            'ACTIVE_EMPLOYEE_SUBSTITUTION',
            'PENDING_OWN_PHONE_NUMBER_CAMPAIGN',
            'SERVICE_REQUESTS_TO_HANDLE',
        ], task.type);
    },
    CARS: function (task) {
        return _.includes([
            'NEAR_LAST_DATE_FOR_ORDER_CHANGE',
            'REVERT_CAR_HANDOVER_APPLICATIONS',
            'CORRECTING_REGISTRATION_APPLICATIONS',
            'HANDOVER_DATE_TO_FILL',
            'BASIC_DATA_TO_FILL',
            'ASSISTANCE_START_DATE_TO_FILL',
            'CAR_HANDOVER_TO_DO',
            'SCHEDULED_CAR_HANDOVERS',
            'HANDOVER_DATE_TO_CORRECT',
            'ORDER_TO_WITHDRAW',
            'VEHICLE_CARD_APPLICATION_FOR_REGISTRATION_CAMPAIGN_TO_SUBMIT',
            'MISSING_EUROCODES',
            'DELEGATED_TICKETS',
            'REGULAR_OFFER_TO_ASSIGN',
            'FLEET_CAR_RESERVATIONS_TO_ACCEPT'
        ], task.type);
    },
    APPLICATIONS: function (task) {
        return _.includes([
            'ORDER_WITHDRAWAL_APPLICATIONS_TO_ACCEPT',
            'ORDER_WITHDRAWAL_APPLICATIONS_TO_ACCEPT_WITHOUT_SETTLEMENT',
        ], task.type);
    },
    DRS_DISCOUNTS: function (task) {
        return _.includes([
            'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_ACCEPT',
            'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_GIVE_SALES_OPINION',
            'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_GIVE_ACCOUNTING_OPINION',
            'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_CORRECT',
            'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_SUPPLEMENT_CAR_NET_LIST_PRICE',
            'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_VERIFY_NEGATIVE_OPINION',
            'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_ACCEPT_FINALLY'
        ], task.type);
    },
    VCP_DISCOUNTS: function (task) {
        return _.includes([
            'CONCESSION_APPLICATIONS_TO_FILL',
            'CONCESSION_APPLICATIONS_TO_ACCEPT',
            'CONCESSION_APPLICATION_SUPPLEMENTS_TO_ACCEPT',
            'CONCESSION_APPLICATIONS_TO_SETTLE',
            'CONCESSION_APPLICATIONS_SETTLEMENT_CORRECTION_APPLICATIONS_TO_ACCEPT',
            'PENDING_CONCESSION_SETTLEMENT_DOCUMENTATIONS',
            'ORDER_TO_SETTLE',
            'ORDER_TO_SETTLE_WITH_SPECIAL_OFFER'
        ], task.type);
    },
    MARKETING: function (task) {
        return _.includes([
            'DEALER_MARKETING_BUDGET_TARGETS_APPLICATIONS',
            'DEALER_MARKETING_EVENTS_TO_FILL',
            'DEALER_MARKETING_EVENTS_TO_REPORT',
        ], task.type);
    },
    INVOICES: function (task) {
        return _.includes([
            'INVOICE_APPLICATIONS_TO_ACCEPT',
            'CORRECTING_INVOICE_APPLICATIONS_TO_HANDLE',
            'TRANSFER_APPLICATIONS_TO_ACCEPT',
        ], task.type);
    },
};
var typeOrder = [
    'ACTIVE_EMPLOYEE_SUBSTITUTION',
    'LEADS_ASSISTANT_ASSIGNMENT',
    'CLIENTS_ASSISTANT_ASSIGNMENT',
    'CLIENTS_WITH_NOT_ACTIVE_ASSISTANT',
    'LEADS',
    'USED_CARS_LEAD_TO_PICK_UP',
    'SCHEDULED_RETURNING_CLIENT_CALLS',
    'CONSENT_WITHDRAWAL_APPLICATIONS',
    'CLIENTS_WITHOUT_CONSENTS',
    'REGULAR_OFFER_TO_ASSIGN',
    'BASIC_DATA_TO_FILL',
    'ORDER_TO_WITHDRAW',
    'NEAR_LAST_DATE_FOR_ORDER_CHANGE',
    'ASSISTANCE_START_DATE_TO_FILL',
    'HANDOVER_DATE_TO_FILL',
    'HANDOVER_DATE_TO_CORRECT',
    'CAR_HANDOVER_TO_DO',
    'ORDER_TO_SETTLE',
    'ORDER_TO_SETTLE_WITH_SPECIAL_OFFER',
    'CONCESSION_APPLICATIONS_TO_ACCEPT',
    'CONCESSION_APPLICATIONS_TO_FILL',
    'CONCESSION_APPLICATION_SUPPLEMENTS_TO_ACCEPT',
    'CONCESSION_APPLICATIONS_TO_SETTLE',
    'CONCESSION_APPLICATIONS_SETTLEMENT_CORRECTION_APPLICATIONS_TO_ACCEPT',
    'INVOICE_APPLICATIONS_TO_ACCEPT',
    'CORRECTING_INVOICE_APPLICATIONS_TO_HANDLE',
    'TRANSFER_APPLICATIONS_TO_ACCEPT',
    'CORRECTING_REGISTRATION_APPLICATIONS',
    'REVERT_CAR_HANDOVER_APPLICATIONS',
    'ORDER_WITHDRAWAL_APPLICATIONS_TO_ACCEPT',
    'ORDER_WITHDRAWAL_APPLICATIONS_TO_ACCEPT_WITHOUT_SETTLEMENT',
    'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_ACCEPT',
    'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_GIVE_SALES_OPINION',
    'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_GIVE_ACCOUNTING_OPINION',
    'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_CORRECT',
    'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_SUPPLEMENT_CAR_NET_LIST_PRICE',
    'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_VERIFY_NEGATIVE_OPINION',
    'EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_ACCEPT_FINALLY',
    'DEALER_MARKETING_BUDGET_TARGETS_APPLICATIONS',
    'DEALER_MARKETING_EVENTS_TO_FILL',
    'DEALER_MARKETING_EVENTS_TO_REPORT',
    'PENDING_CONCESSION_SETTLEMENT_DOCUMENTATIONS',
    'SERVICE_REQUESTS_TO_HANDLE',
    'PENDING_OWN_PHONE_NUMBER_CAMPAIGN',
    'PENDING_COLD_CALLINGS',
    'VEHICLE_CARD_APPLICATION_FOR_REGISTRATION_CAMPAIGN_TO_SUBMIT',
    'MISSING_EUROCODES',
    'DELEGATED_TICKETS',
    'FLEET_CAR_RESERVATIONS_TO_ACCEPT'
];
var scheduledTasksIds = [
    'SCHEDULED_MEETINGS',
    'SCHEDULED_CALLS',
    'SCHEDULED_OTHER_CONTACTS',
    'SCHEDULED_TEST_DRIVES',
    'SCHEDULED_DEMO_CAR_HANDOVERS',
    'SCHEDULED_DEMO_CAR_RETURNS',
    'SCHEDULED_CAR_HANDOVERS',
];
