import React from 'react';
import { shortPartyDescription } from '../../common/party';
import { modelName } from '../../common/models';
export function HandoverEvent(_a) {
    var event = _a.event;
    return (React.createElement("div", { className: "well well-sm" },
        React.createElement("span", null,
            React.createElement("b", null, modelName(event.payload.modelId)),
            " o numerze VIN: ",
            event.payload.id),
        React.createElement("span", null,
            " dla ",
            shortPartyDescription(event.payload.party),
            ".")));
}
