import axios from 'axios';
import { useState } from 'react';
export function useProgressTracker() {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    function withProgressTracking(callback) {
        setLoading(true);
        return callback
            .then(function (result) {
            setLoading(false);
            return result;
        })
            .catch(function (error) {
            if (!axios.isCancel(error)) {
                setLoading(false);
            }
            return Promise.reject(error);
        });
    }
    return {
        loading: loading,
        withTracking: withProgressTracking
    };
}
