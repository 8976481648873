import { AbstractTask } from './abstractTask.component';
import { modelName } from '../../../common/models';
export function HandoverDateToFillTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Zaplanuj wydanie',
        description: "".concat(task.representativeName, " ").concat(modelName(task.model)),
        redirectUrl: "#/orders/".concat(task.orderId, "/planned-handover")
    });
}
