import { useHttp } from 'http/httpService';
import * as _ from 'lodash';
export function useDashboardTasks() {
    var http = useHttp();
    return {
        load: function () {
            return http.get('api/dashboard/tasks')
                .then(function (response) { return flatten(response.data); });
        },
        loadWithoutBackOffice: function () {
            return http
                .get('api/dashboard/tasks?excludingRole=BACK_OFFICE')
                .then(function (response) { return flatten(response.data); });
        },
        loadForUser: function (login) {
            return http
                .get("api/dashboard/tasks?of=".concat(login))
                .then(function (response) { return flatten(response.data); });
        }
    };
    function flatten(response) {
        return _.chain(response)
            .keys()
            .map(function (type) {
            return _.map(response[type], function (task) {
                task.type = type;
                var textIndex = textIndexOf(task);
                if (textIndex) {
                    task.textIndex = textIndex.toLowerCase();
                }
                return task;
            });
        })
            .flatten()
            .value();
    }
    function textIndexOf(task) {
        var textIndexPath = {
            LEADS: 'name',
            LEADS_ASSISTANT_ASSIGNMENT: 'name',
            CLIENTS_ASSISTANT_ASSIGNMENT: 'name',
            CLIENTS_WITHOUT_CONSENTS: 'name',
            SCHEDULED_MEETINGS: 'client',
            SCHEDULED_CALLS: 'client',
            SCHEDULED_RETURNING_CLIENT_CALLS: 'client',
            SCHEDULED_OTHER_CONTACTS: 'client',
            SCHEDULED_TEST_DRIVES: 'client',
            SCHEDULED_DEMO_CAR_HANDOVERS: 'client',
            SCHEDULED_DEMO_CAR_RETURNS: 'client',
            HANDOVER_DATE_TO_FILL: 'representativeName',
            BASIC_DATA_TO_FILL: 'representativeName',
            ASSISTANCE_START_DATE_TO_FILL: 'representativeName',
            CAR_HANDOVER_TO_DO: 'representativeName',
            HANDOVER_DATE_TO_CORRECT: 'representativeName',
            CONCESSION_APPLICATIONS_TO_FILL: 'client',
            CONCESSION_APPLICATIONS_TO_ACCEPT: 'client',
            CONCESSION_APPLICATION_SUPPLEMENTS_TO_ACCEPT: 'client',
            CONCESSION_APPLICATIONS_TO_SETTLE: 'client',
            PENDING_CONCESSION_SETTLEMENT_DOCUMENTATIONS: 'partyName',
            ORDER_TO_SETTLE: 'representativeName',
            ORDER_TO_SETTLE_WITH_SPECIAL_OFFER: 'representativeName',
            EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_ACCEPT: 'party.name',
            EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_GIVE_SALES_OPINION: 'party.name',
            EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_GIVE_ACCOUNTING_OPINION: 'party.name',
            EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_CORRECT: 'party.name',
            EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_SUPPLEMENT_CAR_NET_LIST_PRICE: 'party.name',
            EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_VERIFY_NEGATIVE_OPINION: 'party.name',
            EXTRA_SALE_DISCOUNT_APPLICATIONS_TO_ACCEPT_FINALLY: 'party.name',
            REGULAR_OFFER_TO_ASSIGN: 'representativeName'
        };
        return _.get(task, textIndexPath[task.type], '');
    }
}
