import { AbstractTask } from './abstractTask.component';
import { polishDate } from '../../../common/formatters/date';
export function DealerMarketingEventsToFillTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Uzupełnij wydarzenie',
        description: "".concat(task.name, " ").concat(polishDate(task.startDate)),
        redirectUrl: "#/dealer-marketing-event/".concat(task.id)
    });
}
