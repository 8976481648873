export var XS = 'xs';
export var S = 's';
export var L = 'l';
export var XL = 'xl';
export var CONTRACT = 'contract';
export var CONTRACT_OVERLAY = 'contract-overlay';
export var EXPAND_LEFT = 'expand-left';
export var EXPAND_RIGHT = 'expand-right';
export var EXPAND_UP = 'expand-up';
export var EXPAND_DOWN = 'expand-down';
export var SLIDE_LEFT = 'slide-left';
export var SLIDE_RIGHT = 'slide-right';
export var SLIDE_UP = 'slide-up';
export var SLIDE_DOWN = 'slide-down';
export var ZOOM_IN = 'zoom-in';
export var ZOOM_OUT = 'zoom-out';
