import { polishTimestamp } from 'common/formatters/date';
import { PolishZloty } from 'common/formatters/PolishZloty';
import { extraSaleDiscountApplicationChangeName } from 'extra-sale-discounts/extraSaleDiscountApplicationChangeName';
import { roundedPercentage } from 'extra-sale-discounts/roundedPercentage';
import React from 'react';
export function ChangesHistory(props) {
    var _a;
    return React.createElement("div", { className: "form-horizontal col-lg-12" },
        React.createElement("div", { className: "form-inline" },
            React.createElement("div", { className: "form-group col-lg-12" },
                React.createElement("label", { htmlFor: "changesHistory", className: "control-label col-lg-3" }, "Historia zmian"),
                React.createElement("div", { className: "col-lg-9" },
                    React.createElement("table", { className: "table table-striped" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "Data Godzina"),
                                React.createElement("th", null, "Status"),
                                React.createElement("th", null, "Komentarz"),
                                React.createElement("th", null, "Wnioskowana kwota"),
                                React.createElement("th", null, "Warto\u015B\u0107 rabatu (%)"),
                                React.createElement("th", null, "Wprowadzaj\u0105cy"))),
                        React.createElement("tbody", null, (_a = props.changes) === null || _a === void 0 ? void 0 : _a.map(function (statusChange, changeIndex) {
                            return React.createElement("tr", { key: changeIndex },
                                React.createElement("td", null, polishTimestamp(statusChange.timestamp)),
                                React.createElement("td", null, extraSaleDiscountApplicationChangeName(statusChange.change)),
                                React.createElement("td", null, statusChange.comment),
                                React.createElement("td", null,
                                    React.createElement(PolishZloty, null, statusChange.requestedNetDiscount)),
                                React.createElement("td", null, roundedPercentage(statusChange.requestedNetDiscount, statusChange.netListPrice)),
                                React.createElement("td", null, statusChange.applier));
                        })))))));
}
