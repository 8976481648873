import { modelName } from 'common/models';
import { AbstractTask } from './abstractTask.component';
export function RegularOfferToAssignTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Zaktualizuj ofertę w zamówieniu przedsprzedażowym',
        description: "".concat(task.representativeName, " ").concat(modelName(task.model)),
        redirectUrl: "#/orders/".concat(task.orderId, "/prices")
    });
}
