import React from 'react';
import { modelName } from '../../common/models';
import { shortPartyDescription } from '../../common/party';
export function OrderWithdrawalEvent(_a) {
    var event = _a.event;
    return (React.createElement("div", { className: "well well-sm" },
        React.createElement("b", null, modelName(event.payload.modelId)),
        React.createElement("span", null,
            " dla ",
            event.payload.party.name ? shortPartyDescription(event.payload.party) : 'zanonimizowanej strony'),
        "."));
}
