import { AbstractTask } from './abstractTask.component';
export function ClientsWithoutConsentsTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Uzupełnij zgody',
        description: task.name,
        redirectUrl: "#/customer-service/".concat(task.id, "/")
    });
}
