// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react .file-upload-form table td:first-child {
  padding-top: 7px;
}
#react .file-upload-form .file-upload-input {
  display: none;
}
#react .file-upload-form .remove-attachment-btn {
  cursor: pointer;
}
#react .file-upload-form .add-attachment-btn {
  cursor: pointer;
}
#react .file-upload-form .body-without-header > tbody > tr:first-child > td {
  border-top: none;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/FileUploadForm.less"],"names":[],"mappings":"AAAA;EAGM,gBAAA;AADN;AAFA;EAQI,aAAA;AAHJ;AALA;EAYI,eAAA;AAJJ;AARA;EAgBI,eAAA;AALJ;AAXA;EAoBI,gBAAA;AANJ","sourcesContent":[".file-upload-form {\n  table {\n    td:first-child {\n      padding-top: 7px;\n    }\n  }\n\n  .file-upload-input {\n    display: none;\n  }\n\n  .remove-attachment-btn {\n    cursor: pointer;\n  }\n\n  .add-attachment-btn {\n    cursor: pointer;\n  }\n\n  .body-without-header>tbody>tr:first-child>td {\n    border-top: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
