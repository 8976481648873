import { AbstractTask } from './abstractTask.component';
import { phoneNumber } from '../../../common/phoneNumber';
import { polishTimestamp } from '../../../common/formatters/date';
export function LeadsTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: polishTimestamp(task.contactUntil),
        action: 'Lead',
        description: [task.name, phoneNumber(task.phoneNumber), task.email, task.comment].filter(function ($) { return !!$; }).join(' '),
        redirectUrl: "#/lead/".concat(task.id)
    });
}
