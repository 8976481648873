import { DateInput } from 'common/dateInput.component';
import { formatDate, parseDate } from 'common/formatters/date';
import { useLocalStorageState } from 'common/hooks/useLocalStorageState';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import { getTooltipContainerElement } from 'common/tooltipContainer';
import userContext from 'common/userContext';
import { useSalesList } from 'employees/sales';
import * as _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../common/components/Button.component';
import Multiselect from '../common/components/Multiselect.component';
import { useAutomaticSaleFunnel } from './automaticSaleFunnel';
import './AutomaticSaleFunnel.less';
import { useOrganizations } from './organizations';
export var AutomaticSaleFunnel = withCancellation(function () {
    var salesList = useSalesList();
    var automaticSaleFunnel = useAutomaticSaleFunnel();
    var organizations = useOrganizations();
    var tooltipContainerElement = getTooltipContainerElement();
    var sectionsTitles = {
        NOT_CONVERTED: 'W trakcie obsługi',
        CONVERTED: 'Obsłużone',
        LOST: 'Utracone'
    };
    var _a = useLocalStorageState('automatic-sale-funnel_from-date', moment().subtract(1, 'month'), formatDate, parseDate), recentlyUsedFromDate = _a[0], setRecentlyUsedFromDate = _a[1];
    var _b = useLocalStorageState('automatic-sale-funnel_to-date', moment(), formatDate, parseDate), recentlyUsedToDate = _b[0], setRecentlyUsedToDate = _b[1];
    var _c = useLocalStorageState('automatic-sale-funnel_sales', []), recentlyUsedSales = _c[0], setRecentlyUsedSales = _c[1];
    var _d = useLocalStorageState('automatic-sale-funnel_dealer', undefined), recentlyUsedDealer = _d[0], setRecentlyUsedDealer = _d[1];
    var _e = useState(recentlyUsedFromDate), fromDate = _e[0], setFromDate = _e[1];
    var _f = useState(recentlyUsedToDate), toDate = _f[0], setToDate = _f[1];
    var _g = useState('NOT_CONVERTED'), selectedSection = _g[0], setSelectedSection = _g[1];
    var _h = useState({
        leadStage: [],
        clientStage: [],
        offeredClientStage: [],
        orderStage: [],
        handoverStage: [],
        carUserStage: []
    }), notConverted = _h[0], setNotConverted = _h[1];
    var _j = useState({
        leadStage: [],
        clientStage: [],
        offeredClientStage: [],
        orderStage: [],
        handoverStage: [],
        carUserStage: []
    }), converted = _j[0], setConverted = _j[1];
    var _k = useState({
        leadStage: [],
        clientStage: [],
        offeredClientStage: [],
        orderStage: [],
        handoverStage: [],
        carUserStage: []
    }), lost = _k[0], setLost = _k[1];
    var salesSelect = useSalesSelect();
    var dealersSelect = useDealersSelect();
    useEffect(function () {
        if (!canSelectDealers() && !canSelectSales()) {
            loadAutomaticSaleFunnel(fromDate, toDate);
        }
    }, []);
    function useSalesSelect() {
        var _a = useState([]), options = _a[0], setOptions = _a[1];
        var _b = useState([]), selectedValues = _b[0], setSelectedValues = _b[1];
        useEffect(function () {
            if (canSelectSales()) {
                salesList.load()
                    .then(function (sales) { return sales.sort(function (left, right) { return (left.name || left.login).localeCompare((right.name || right.login), 'pl'); }); })
                    .then(function (sales) {
                    salesSelect.setOptions(sales);
                    var selectedSales = sales.filter(function ($) { return _.includes(recentlyUsedSales, $.login); });
                    setSelectedValues(selectedSales);
                    loadAutomaticSaleFunnel(fromDate, toDate, _.map(selectedSales, function ($) { return $.login; }));
                })
                    .catch(function () { });
            }
        }, []);
        return {
            options: options,
            setOptions: setOptions,
            selectedValues: selectedValues,
            setSelectedValues: setSelectedValues
        };
    }
    function useDealersSelect() {
        var _a = useState([]), options = _a[0], setOptions = _a[1];
        var _b = useState(undefined), selectedValue = _b[0], setSelectedValue = _b[1];
        useEffect(function () {
            if (canSelectDealers()) {
                organizations.listDealers()
                    .then(function (dealers) { return dealers.sort(function (left, right) { return left.organizationName.localeCompare(right.organizationName, 'pl'); }); })
                    .then(function (dealers) {
                    dealersSelect.setOptions(dealers);
                    var selectedDealer = _.find(dealers, function ($) { return recentlyUsedDealer === $.organizationId; });
                    if (selectedDealer) {
                        setSelectedValue(selectedDealer.organizationId);
                        loadAutomaticSaleFunnel(fromDate, toDate, [], selectedDealer.organizationId);
                    }
                })
                    .catch(function () { });
            }
        }, []);
        return {
            options: options,
            setOptions: setOptions,
            selectedValue: selectedValue,
            setSelectedValue: function (event) { return setSelectedValue(event.target.value); }
        };
    }
    function loadAutomaticSaleFunnel(fromDate, toDate, salesList, dealer) {
        if (salesList === void 0) { salesList = []; }
        if (dealer === void 0) { dealer = undefined; }
        (canSelectSales() ?
            automaticSaleFunnel.loadForSales(fromDate, toDate, salesList) :
            canSelectDealers() ?
                automaticSaleFunnel.loadForDealer(fromDate, toDate, dealer) :
                automaticSaleFunnel.load(fromDate, toDate))
            .then(function (response) {
            setNotConverted({
                leadStage: response.leadStage.notConverted,
                clientStage: response.clientStage.notConverted,
                offeredClientStage: response.offeredClientStage.notConverted,
                orderStage: response.orderStage.notConverted,
                handoverStage: response.handoverStage.notConverted,
                carUserStage: response.carUserStage.notConverted,
            });
            setConverted({
                leadStage: response.leadStage.converted,
                clientStage: response.clientStage.converted,
                offeredClientStage: response.offeredClientStage.converted,
                orderStage: response.orderStage.converted,
                handoverStage: response.handoverStage.converted,
                carUserStage: response.carUserStage.converted,
            });
            setLost({
                leadStage: response.leadStage.lost,
                clientStage: response.clientStage.lost,
                offeredClientStage: response.offeredClientStage.lost,
                orderStage: response.orderStage.lost,
                handoverStage: response.handoverStage.lost,
                carUserStage: response.carUserStage.lost,
            });
        })
            .catch(function () { });
        storeSelectors();
        function storeSelectors() {
            setRecentlyUsedFromDate(fromDate);
            setRecentlyUsedToDate(toDate);
            setRecentlyUsedSales(salesList);
            setRecentlyUsedDealer(dealer);
        }
    }
    function canSelectSales() {
        return userContext.hasAnyRole('HEAD_OF_SALES');
    }
    function canSelectDealers() {
        return userContext.hasAnyRole('VCP_SALES_MANAGER');
    }
    function show() {
        loadAutomaticSaleFunnel(fromDate, toDate, _.map(salesSelect.selectedValues, function ($) { return $.login; }), dealersSelect.selectedValue);
    }
    return React.createElement("article", { className: "automatic-sale-funnel" },
        React.createElement("section", { className: "controls" },
            React.createElement("label", { htmlFor: "fromDateInput", className: "control-label" }, "Od:"),
            React.createElement(DateInput, { date: fromDate.toISOString(), setDate: function (date) { return setFromDate(parseDate(date)); }, "data-testid": "fromDateInput" }),
            React.createElement("label", { htmlFor: "fromDateInput", className: "control-label" }, "Do:"),
            React.createElement(DateInput, { date: toDate.toISOString(), setDate: function (date) { return setToDate(parseDate(date)); }, "data-testid": "toDateInput" }),
            canSelectSales() ? (React.createElement("div", { className: "controls", "data-testid": "salesInput", style: { margin: 0 } },
                React.createElement("label", { className: "control-label" }, "Handlowcy:"),
                React.createElement(Multiselect, { style: { width: '240px' }, value: salesSelect.selectedValues, options: salesSelect.options, getOptionLabel: function (option) { return option.name || option.login; }, getOptionValue: function (option) { return option.login; }, onChange: function ($) { return salesSelect.setSelectedValues($); } }))) : null,
            canSelectDealers() ? (React.createElement("div", { className: "controls", style: { margin: 0 } },
                React.createElement("label", { className: "control-label" }, "Dealerzy:"),
                React.createElement("select", { id: "dealersInput", "data-testid": "dealersInput", onChange: dealersSelect.setSelectedValue, value: dealersSelect.selectedValue, className: "form-control", style: { width: '350px' } },
                    React.createElement("option", { value: "", style: { display: 'none' } }, "Wybierz"),
                    dealersSelect.options.map(function (dealer) {
                        return React.createElement("option", { value: dealer.organizationId, key: dealer.organizationId }, dealer.organizationName);
                    })))) : null,
            React.createElement(Button, { "data-testid": "show-button", className: "btn btn-sm btn-confirm", disabled: canSelectDealers() && !dealersSelect.selectedValue, onClick: show }, "Poka\u017C")),
        React.createElement(CardsSection, { section: "NOT_CONVERTED", data: notConverted }),
        React.createElement(CardsSection, { section: "CONVERTED", data: converted }),
        React.createElement(CardsSection, { section: "LOST", data: lost }));
    function CardsSection(params) {
        var isSelected = selectedSection === params.section;
        return React.createElement("section", { className: "row panel-default cards-section" },
            React.createElement("div", { className: "cards-section-header", onClick: function () { return setSelectedSection(params.section); }, role: !isSelected ? 'button' : 'none', tabIndex: 0 },
                React.createElement("div", { className: "title" }, sectionsTitles[params.section]),
                !isSelected ? React.createElement("div", { className: "fas fa-chevron-down" }) : null),
            isSelected ? React.createElement("div", { className: "panel-body no-paddings cards-section-columns-container" },
                React.createElement("div", { className: "cards-section-columns" },
                    React.createElement(CardsSectionColumn, { title: "Leady", cards: params.data.leadStage, section: params.section, tooltip: tooltip(params.section, 'LEADS') }),
                    React.createElement(CardsSectionColumn, { title: "Klienci", cards: params.data.clientStage, section: params.section, tooltip: tooltip(params.section, 'CLIENTS') }),
                    React.createElement(CardsSectionColumn, { title: "Oferty", cards: params.data.offeredClientStage, section: params.section, tooltip: tooltip(params.section, 'OFFERED_CLIENTS') }),
                    React.createElement(CardsSectionColumn, { title: "Zam\u00F3wienia", cards: params.data.orderStage, section: params.section, tooltip: tooltip(params.section, 'ORDERS') }),
                    React.createElement(CardsSectionColumn, { title: "Wydania", cards: params.data.handoverStage, section: params.section, tooltip: tooltip(params.section, 'HANDOVERS') }),
                    React.createElement(CardsSectionColumn, { title: "U\u017Cytkownicy", cards: params.data.carUserStage, section: params.section, tooltip: tooltip(params.section, 'CAR_USERS') }))) : null);
        function CardsSectionColumn(params) {
            function onCardLabelClick(cardData) {
                if (cardData.clientId) {
                    window.location.href = "#/customer-service/".concat(cardData.clientId, "/");
                }
                else if (cardData.leadId) {
                    window.location.href = "#/lead/".concat(cardData.leadId);
                }
            }
            return React.createElement("div", { className: "funnel-cards-group", "data-section": params.section },
                React.createElement("div", { className: "title" },
                    React.createElement(OverlayTrigger, { placement: "bottom", rootClose: true, overlay: params.tooltip, container: tooltipContainerElement, delay: 300 },
                        React.createElement("span", { className: "automatic-sale-funnel-title" }, params.title))),
                params.cards.map(function (cardData, index) {
                    return React.createElement("div", { key: index, className: "funnel-card" }, cardData.anonymized
                        ? React.createElement("span", { className: "text-muted" }, "Dane usuni\u0119te")
                        : React.createElement("a", { className: "link", onClick: function () { return onCardLabelClick(cardData); } }, cardData.clientName.trim() === '' ? 'Brak danych osobowych' : cardData.clientName));
                }));
        }
        function tooltip(section, stage) {
            return (React.createElement(Tooltip, { className: "automatic-sale-funnel-tooltip automatic-sale-funnel-tooltip-".concat(stage), id: "automatic-sale-funnel-tooltip-".concat(section, "-").concat(stage), "data-testid": "automatic-sale-funnel-tooltip-".concat(section, "-").concat(stage) },
                React.createElement(TooltipText, { section: section, stage: stage })));
        }
        function TooltipText(params) {
            switch (params.stage) {
                case 'LEADS':
                    return React.createElement("span", null,
                        React.createElement("b", null, "W trakcie obs\u0142ugi"),
                        React.createElement("br", null),
                        "Leady w trakcie obs\u0142ugi to leady nie starsze ni\u017C 7 dni od daty ich utworzenia z kt\u00F3rych nie zosta\u0142 utworzony klient.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Obs\u0142u\u017Cone"),
                        React.createElement("br", null),
                        "Leady obs\u0142u\u017Cone to leady nie starsze ni\u017C 7 dni od daty ich utworzenia z kt\u00F3rych zosta\u0142 utworzony klient.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Utracone"),
                        React.createElement("br", null),
                        "Leady nieobs\u0142u\u017Cone to leady, z kt\u00F3rych nie zosta\u0142 utworzony klient w ci\u0105gu 7 dni.");
                case 'CLIENTS':
                    return React.createElement("span", null,
                        React.createElement("b", null, "W trakcie obs\u0142ugi"),
                        React.createElement("br", null),
                        "Klient w trakcie obs\u0142ugi to nowy lub powracaj\u0105cy klient, kt\u00F3ry nie otrzyma\u0142 oferty. Czas na przedstawienie oferty to 7 dni od daty utworzenia lub powrotu klienta.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Obs\u0142u\u017Cone"),
                        React.createElement("br", null),
                        "Klient obs\u0142u\u017Cony to nowy lub powracaj\u0105cy klient, kt\u00F3remu w\u00A0ci\u0105gu 7 dni od daty utworzenia lub powrotu zosta\u0142a przedstawiona oferta.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Utracone"),
                        React.createElement("br", null),
                        "Klient nieobs\u0142u\u017Cony to nowy lub powracaj\u0105cy klient, kt\u00F3remu w ci\u0105gu 7 dni od daty utworzenia lub powrotu nie zosta\u0142a przedstawiona oferta.");
                case 'OFFERED_CLIENTS':
                    return React.createElement("span", null,
                        React.createElement("b", null, "W trakcie obs\u0142ugi"),
                        React.createElement("br", null),
                        "Klient w trakcie ofertowania to taki, kt\u00F3ry nie z\u0142o\u017Cy\u0142 jeszcze zam\u00F3wienia i nie min\u0119\u0142o wi\u0119cej ni\u017C 7 dni od oferty.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Obs\u0142u\u017Cone"),
                        React.createElement("br", null),
                        "Klient zaofertowany to taki, kt\u00F3ry z\u0142o\u017Cy\u0142 zam\u00F3wienie w ci\u0105gu 7\u00A0dni od otrzymania oferty.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Utracone"),
                        React.createElement("br", null),
                        "Klient niezaofertowany to taki, kt\u00F3ry nie z\u0142o\u017Cy\u0142 zam\u00F3wienia w\u00A0ci\u0105gu 7 dni od otrzymania oferty. Oferta wygas\u0142a.");
                case 'ORDERS':
                    return React.createElement("span", null,
                        React.createElement("b", null, "W trakcie obs\u0142ugi"),
                        React.createElement("br", null),
                        "Zam\u00F3wienie na samoch\u00F3d, kt\u00F3ry nie otrzyma\u0142 jeszcze karty pojazdu i nie zosta\u0142 zarejestrowany.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Obs\u0142u\u017Cone"),
                        React.createElement("br", null),
                        "Zam\u00F3wienie na samoch\u00F3d, kt\u00F3ry ma wydan\u0105 kart\u0119 pojazdu i\u00A0zosta\u0142 zarejestrowany.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Utracone"),
                        React.createElement("br", null),
                        "Zam\u00F3wienie na samoch\u00F3d, kt\u00F3ry nie mia\u0142 wydanej karty pojazdu, nie zosta\u0142 zarejestrowany, lub zam\u00F3wienie zosta\u0142o wycofane.");
                case 'HANDOVERS':
                    return React.createElement("span", null,
                        React.createElement("b", null, "W trakcie obs\u0142ugi"),
                        React.createElement("br", null),
                        "Samoch\u00F3d, kt\u00F3ry oczekuje na wydanie klientowi.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Obs\u0142u\u017Cone"),
                        React.createElement("br", null),
                        "Samoch\u00F3d, kt\u00F3ry zosta\u0142 wydany klientowi.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Utracone"),
                        React.createElement("br", null),
                        "Zam\u00F3wienie na samoch\u00F3d, kt\u00F3ry oczekiwa\u0142 na wydanie, zosta\u0142o wycofane.");
                case 'CAR_USERS':
                    return React.createElement("span", null,
                        React.createElement("b", null, "W trakcie obs\u0142ugi"),
                        React.createElement("br", null),
                        "Samoch\u00F3d, kt\u00F3ry zosta\u0142 wydany klientowi.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Obs\u0142u\u017Cone"),
                        React.createElement("br", null),
                        "Nie wyst\u0119puje.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("b", null, "Utracone"),
                        React.createElement("br", null),
                        "Nie wyst\u0119puje.");
            }
        }
    }
});
