var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemoized } from 'common/hooks/useMemoized';
import { useProgressTracker } from 'common/hooks/useProgressTracker';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import UserContext from '../common/userContext';
import './Dashboard.less';
import { DaySchedule } from './day-schedule/DaySchedule.component';
import { useDashboardTasks } from './tasks';
import { WeekCalendar } from './week-calendar/WeekCalendar.component';
export var DashboardContext = React.createContext({ previewMode: false });
export var Dashboard = withCancellation(function (props) {
    var tasks = useDashboardTasks();
    var _a = useState([]), rawTasks = _a[0], setRawTasks = _a[1];
    var _b = useState({ phrase: '', category: 'ALL' }), filters = _b[0], setFilters = _b[1];
    var loadingTracker = useProgressTracker();
    var allTasks = useMemoized(tasks.load);
    var withoutBackofficeTasks = useMemoized(tasks.loadWithoutBackOffice);
    var userTasks = useMemoized(function (login) { return tasks.loadForUser(login); });
    useEffect(function () {
        if (salesSelectedManually()) {
            selectUserTasks(props.sales);
        }
        else {
            selectTasks(false);
            loadFiltersFromParams();
        }
    }, [props.sales]);
    function loadFiltersFromParams() {
        var url = new URL(window.location.href.replace('#', ''));
        if (!!url.searchParams.get('filterPhrase')) {
            setFilters(function (prevFilters) { return (__assign(__assign({}, prevFilters), { phrase: url.searchParams.get('filterPhrase') })); });
        }
        if (!!url.searchParams.get('categoryFilter')) {
            setFilters(function (prevFilters) { return (__assign(__assign({}, prevFilters), { category: url.searchParams.get('categoryFilter') })); });
        }
    }
    function selectTasks(withoutBackoffice) {
        if (withoutBackoffice) {
            loadingTracker.withTracking(withoutBackofficeTasks()).then(setRawTasks).catch(function () { });
        }
        else {
            loadingTracker.withTracking(allTasks()).then(setRawTasks).catch(function () { });
        }
    }
    function selectUserTasks(sales) {
        loadingTracker.withTracking(userTasks(sales)).then(setRawTasks).catch(function () { });
    }
    function showCalendar() {
        return UserContext.hasAnyRole('SALES');
    }
    function salesSelectedManually() {
        return !!props.sales;
    }
    return (React.createElement(DashboardContext.Provider, { value: { previewMode: salesSelectedManually() } },
        React.createElement("div", { className: "dashboard", "data-testid": "dashboard" }, showCalendar() ?
            React.createElement(Tabs, { className: "tabs-container", id: "dashboard-nav", defaultActiveKey: "day-schedule", mountOnEnter: true },
                React.createElement(Tab, { className: "dashboard__tab", eventKey: "day-schedule", title: "Lista zada\u0144" }, salesSelectedManually() ?
                    React.createElement(DaySchedule, { tasks: rawTasks, filters: filters, loading: loadingTracker.loading }) :
                    React.createElement(DaySchedule, { tasks: rawTasks, filters: filters, loading: loadingTracker.loading, onExcludeBackOfficeChange: selectTasks })),
                React.createElement(Tab, { className: "dashboard__tab", eventKey: "week-calendar", title: "Kalendarz" },
                    React.createElement(WeekCalendar, { tasks: rawTasks, filters: filters }))) :
            React.createElement(React.Fragment, null, salesSelectedManually() ?
                React.createElement(DaySchedule, { tasks: rawTasks, filters: filters, loading: loadingTracker.loading }) :
                React.createElement(DaySchedule, { tasks: rawTasks, filters: filters, loading: loadingTracker.loading, onExcludeBackOfficeChange: selectTasks })))));
});
