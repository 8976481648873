import React, { useState } from 'react';
import moment from 'moment';
import { FileLinkButton } from '../../common/components/FileLinkButton.component';
import { DateInput } from '../../common/dateInput.component';
import { formatDate, parseDate } from '../../common/formatters/date';
export function ReportByDatesRange(props) {
    var _a = useState(moment().startOf('year')), fromDate = _a[0], setFromDate = _a[1];
    var _b = useState(moment()), toDate = _b[0], setToDate = _b[1];
    return (React.createElement("article", null,
        React.createElement("section", { className: 'row panel panel-default' },
            React.createElement("div", { className: 'panel-heading' },
                React.createElement("h4", { className: 'panel-title' }, props.title)),
            React.createElement("div", { className: 'panel-body form-horizontal' },
                React.createElement("div", { className: 'form-group' },
                    React.createElement("label", { htmlFor: "validFrom", className: "col-lg-2 control-label" }, "Od:"),
                    React.createElement("div", { style: { margin: '0 4px' } },
                        React.createElement(DateInput, { date: fromDate.toISOString(), setDate: function (date) { return setFromDate(parseDate(date)); }, "data-testid": "fromDateInput" }))),
                React.createElement("div", { className: "form-group", style: { marginBottom: 0, position: 'relative', zIndex: 99 } },
                    React.createElement("label", { htmlFor: "validTo", className: "col-lg-2 control-label" }, "Do:"),
                    React.createElement("div", { style: { margin: '0 4px' } },
                        React.createElement(DateInput, { date: toDate.toISOString(), setDate: function (date) { return setToDate(parseDate(date)); }, "data-testid": "toDateInput" }))))),
        React.createElement("section", { className: 'row' },
            React.createElement("div", { className: 'pull-right' },
                React.createElement(FileLinkButton, { "data-testid": 'download-report-button', fileLocation: props.fileLocation(formatDate(fromDate), formatDate(toDate)) }, "Generuj")))));
}
