// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react .dashboard {
  margin-left: -15px;
  margin-right: -15px;
}
#react .dashboard__tab {
  margin-top: 20px;
}
#react .tasks .tasks__list {
  height: calc(100vh - 250px);
}
#react .tabs-container .tasks .tasks__list {
  height: calc(100vh - 310px);
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/Dashboard.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAGA;EAEI,2BAAA;AAFJ;AAMA;EAEI,2BAAA;AALJ","sourcesContent":[".dashboard {\n  margin-left: -15px;\n  margin-right: -15px;\n\n  &__tab {\n    margin-top: 20px;\n  }\n}\n\n.tasks {\n  .tasks__list {\n    height: calc(100vh - 250px);\n  }\n}\n\n.tabs-container .tasks {\n  .tasks__list {\n    height: calc(100vh - 310px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
