// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react .list-value.table-next-to-label td:first-child {
  padding-top: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/common/components/ListValue.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;AAAJ","sourcesContent":[".list-value.table-next-to-label {\n  td:first-child {\n    padding-top: 7px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
