var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from 'react';
export function useMemoized(valueLoader) {
    var _a = useState({}), cache = _a[0], setCache = _a[1];
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var cacheKey = JSON.stringify(args);
        return new Promise(function (resolve, reject) {
            if (cache[cacheKey]) {
                resolve(cache[cacheKey]);
            }
            else {
                valueLoader.apply(void 0, args).then(function (value) {
                    setCache(function (prevCache) {
                        var _a;
                        return (__assign(__assign({}, prevCache), (_a = {}, _a[cacheKey] = value, _a)));
                    });
                    return resolve(value);
                }).catch(function (error) { return reject(error); });
            }
        });
    };
}
