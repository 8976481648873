import { AbstractTask } from './abstractTask.component';
export function DealerMarketingEventsToReportTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Wyślij raport',
        description: task.name,
        redirectUrl: "#/dealer-marketing-event-preview/".concat(task.id, "/report-submission")
    });
}
