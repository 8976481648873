import { AbstractTask } from './abstractTask.component';
export function UnknownTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: undefined,
        action: 'Nieznany typ zadania',
        description: undefined
    });
}
