// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react .search .extra-sale-discount-sections-selector {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/extra-sale-discounts/search/Search.less"],"names":[],"mappings":"AAAA;EAGQ,kBAAA;EACA,mBAAA;EACA,mBAAA;AADR","sourcesContent":[".search {\n\n    .extra-sale-discount-sections-selector {\n        margin-left: -15px;\n        margin-right: -15px;\n        margin-bottom: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
