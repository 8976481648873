import { AutomaticSaleFunnel } from 'automatic-sale-funnel/AutomaticSaleFunnel.component';
import { ModalContainer } from 'common/message-box/messageBox';
import { TooltipContainer } from 'common/tooltipContainer';
import { Dashboard } from 'dashboard/Dashboard.component';
import { Employees } from 'employees/Employees.component';
import { AcceptanceForm } from 'extra-sale-discounts/acceptance-form/AcceptanceForm.component';
import { OpinionForm } from 'extra-sale-discounts/opinion-form/OpinionForm.component';
import { Preview } from 'extra-sale-discounts/preview/Preview.component';
import { CarNetListPriceSupplementForm } from 'extra-sale-discounts/submission-form/CarNetListPriceSupplementForm.component';
import { EditSubmissionForm } from 'extra-sale-discounts/submission-form/EditSubmissionForm.component';
import { NewSubmissionForm } from 'extra-sale-discounts/submission-form/NewSubmissionForm.component';
import { NewPartWholesaler } from 'parts-wholesale-whitelisted-parties/NewPartWholesaler.component';
import { PartsWholesaleWhitelistedParties } from 'parts-wholesale-whitelisted-parties/PartsWholesaleWhitelistedParties.component';
import { PartWholesaler } from 'parts-wholesale-whitelisted-parties/PartWholesaler.component';
import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ReportByDatesRange } from 'reports/report-by-dates-range/ReportByDatesRange.component';
import { ReportByYear } from 'reports/report-by-year/ReportByYear.component';
import { Search } from './extra-sale-discounts/search/Search.component';
import './root.less';
export default function Root() {
    return React.createElement(Router, null,
        React.createElement(App, null));
}
export function App() {
    var forceUpdate = useForceUpdate();
    return React.createElement("div", { className: "screen container", key: forceUpdate },
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/dashboard" },
                React.createElement(Dashboard, null)),
            React.createElement(Route, { path: "/employees" },
                React.createElement(Employees, null)),
            React.createElement(Route, { path: "/automatic-sale-funnel" },
                React.createElement(AutomaticSaleFunnel, null)),
            React.createElement(Route, { path: ['/extra-sale-discounts/submission/new/:clientId/:partyId', '/extra-sale-discounts/submission/new/:clientId'] },
                React.createElement(NewSubmissionForm, null)),
            React.createElement(Route, { path: "/extra-sale-discounts/submission/supplement-car-net-list-price/:applicationId" },
                React.createElement(CarNetListPriceSupplementForm, null)),
            React.createElement(Route, { path: "/extra-sale-discounts/submission/:applicationId" },
                React.createElement(EditSubmissionForm, null)),
            React.createElement(Route, { path: "/extra-sale-discounts/acceptance/:applicationId" },
                React.createElement(AcceptanceForm, null)),
            React.createElement(Route, { path: "/extra-sale-discounts/opinion/:granter/:applicationId" },
                React.createElement(OpinionForm, null)),
            React.createElement(Route, { path: "/extra-sale-discounts/search-preview/:applicationId" },
                React.createElement(Search, null)),
            React.createElement(Route, { path: "/extra-sale-discounts/preview/:applicationId" },
                React.createElement(Preview, null)),
            React.createElement(Route, { path: "/extra-sale-discounts/search-preview/" },
                React.createElement(Search, null)),
            React.createElement(Route, { path: "/parts-wholesalers-list" },
                React.createElement(PartsWholesaleWhitelistedParties, null)),
            React.createElement(Route, { path: "/parts-wholesaler/new" },
                React.createElement(NewPartWholesaler, null)),
            React.createElement(Route, { path: "/parts-wholesaler/:nip/:organizationId" },
                React.createElement(PartWholesaler, null)),
            React.createElement(Route, { path: "/consents-at-the-time-of-handover-report" },
                React.createElement(ReportByDatesRange, { title: 'Generowanie raportu zgód w momencie wydania', fileLocation: function (from, to) { return "/consents-at-the-time-of-handover-report/".concat(from, "_").concat(to, "/csv"); } })),
            React.createElement(Route, { path: "/repayment-debt-report" },
                React.createElement(ReportByDatesRange, { title: 'Generowanie raportu rozpoczętych gwarancji', fileLocation: function (from, to) { return "/repayment-debt-report/".concat(from, "_").concat(to, "/csv"); } })),
            React.createElement(Route, { path: "/extra-sale-discount-report" },
                React.createElement(ReportByDatesRange, { title: 'Generowanie raportu rabatów DRS', fileLocation: function (from, to) { return "/extra-sale-discount-report/".concat(from, "_").concat(to, "/csv"); } })),
            React.createElement(Route, { path: "/plugsurfing-report" },
                React.createElement(ReportByDatesRange, { title: 'Generowanie raportu kart Plugsurfing', fileLocation: function (from, to) { return "/plugsurfing-report/".concat(from, "_").concat(to, "/csv"); } })),
            React.createElement(Route, { path: "/handover-with-service-package-report" },
                React.createElement(ReportByDatesRange, { title: 'Generowanie raportu wydań z pakietami serwisowymi', fileLocation: function (from, to) { return "/handover-with-service-package-report/".concat(from, "_").concat(to, "/csv"); } })),
            React.createElement(Route, { path: "/dealers-activity-lead-audit-report" },
                React.createElement(ReportByYear, { title: 'Generowanie leadów do analizy aktywności dealerów', minYear: 2019, fileLocation: function (year) { return "/dealers-activity-lead-audit/".concat(year, "/csv"); } })),
            React.createElement(Route, { path: "/fleet-orders-report" },
                React.createElement(ReportByYear, { title: 'Generowanie raportu zamówień flotowych', minYear: 2015, fileLocation: function (year) { return "/fleet-orders/".concat(year, "/csv"); } })),
            React.createElement(Route, { path: "/detailed-lead-to-order-conversion-report" },
                React.createElement(ReportByDatesRange, { title: 'Generowanie szczegółowego raportu konwersji leadów na zamówienia', fileLocation: function (from, to) { return "/detailed-lead-to-order-conversion-report/".concat(from, "_").concat(to, "/csv"); } })),
            React.createElement(Route, { path: "/unconfirmed-leads-report" },
                React.createElement(ReportByDatesRange, { title: 'Generowanie raportu leadów niepotwierdzonych', fileLocation: function (from, to) { return "/cc-completed-task-failure-reason-report/".concat(from, "_").concat(to, "/csv"); } })),
            React.createElement(Route, { path: "/service-settlements-report" },
                React.createElement(ReportByYear, { title: 'Generowanie raportu rozliczeń akcji serwisowych', minYear: 2019, fileLocation: function (year) { return "/service-settlements/report/csv?year=".concat(year); } })),
            React.createElement(Route, { exact: true, path: "/" },
                React.createElement(Redirect, { to: "/login" }))),
        React.createElement(ModalContainer, null),
        React.createElement(TooltipContainer, null));
}
function useForceUpdate() {
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var listener = function () { return setValue(function (prev) { return prev + 1; }); };
        window.addEventListener('force-reload', listener);
        return function () { return window.removeEventListener('force-reload', listener); };
    }, []);
    return value;
}
