import { concessionStatus } from 'common/formatters/concessionFormatters';
import { polishDate } from 'common/formatters/date';
import { shortPartyDescription } from 'common/party';
import React from 'react';
export function ConcessionApplicationEvent(_a) {
    var event = _a.event;
    function showValidTo() {
        var isSpecialOffer = event.payload.number ? event.payload.number.startsWith('OS/') : false;
        if (isSpecialOffer) {
            return event.payload.status === 'APPROVED' && event.payload.validTo;
        }
        else {
            return event.payload.validTo;
        }
    }
    return (React.createElement("div", { className: "well well-sm" },
        React.createElement("span", null, event.payload.extended ? 'Rozszerzenie ' : ''),
        React.createElement("span", null, event.payload.number),
        React.createElement("span", null,
            " dla ",
            shortPartyDescription(event.payload.party),
            "."),
        showValidTo() ? React.createElement("span", null,
            " Wa\u017Cny do: ",
            React.createElement("b", null, polishDate(event.payload.validTo)),
            ".") : null,
        React.createElement("span", null,
            " Status: ",
            React.createElement("b", null, concessionStatus(event.payload.status)))));
}
