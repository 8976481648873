import React from 'react';
import { modelName } from '../../common/models';
import { shortPartyDescription } from '../../common/party';
export function OrderEvent(_a) {
    var event = _a.event;
    return (React.createElement("div", { className: "well well-sm" },
        React.createElement("b", null, modelName(event.payload.modelId)),
        event.payload.dealerOrder ? React.createElement("span", null,
            " o numerze DO: ",
            event.payload.dealerOrder) : null,
        event.payload.vin ? React.createElement("span", null,
            " oraz VIN: ",
            event.payload.vin) : null,
        React.createElement("span", null,
            " dla ",
            shortPartyDescription(event.payload.party)),
        event.payload.printedOfferLocation ? React.createElement("span", null,
            " na podstawie ",
            event.payload.offerNumber) : null,
        "."));
}
