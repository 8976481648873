import { AbstractTask } from './abstractTask.component';
import { shortPartyDescription } from '../../../common/party';
export function ConcessionApplicationsToFillTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Uzupełnij wniosek o rabat VCP',
        description: [task.client, shortPartyDescription(task.party)].filter(function ($) { return $; }).join(' - '),
        redirectUrl: "#/concessions/submission/".concat(task.id, "/")
    });
}
