var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import Ladda from 'ladda';
var isUndefined = function (value) { return typeof value === 'undefined'; };
var OMITTED_PROPS = [
    'loading',
    'progress',
];
var omit = function (data, keys) {
    var result = {};
    Object.keys(data).forEach(function (key) {
        if (keys.indexOf(key) === -1) {
            result[key] = data[key];
        }
    });
    return result;
};
var AdaptedLaddaButton = /** @class */ (function (_super) {
    __extends(AdaptedLaddaButton, _super);
    function AdaptedLaddaButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.setNode = function (node) {
            _this.node = node;
        };
        return _this;
    }
    AdaptedLaddaButton.prototype.componentDidMount = function () {
        this.laddaInstance = Ladda.create(this.node);
        if (this.props.loading) {
            this.laddaInstance.start();
        }
        if (!isUndefined(this.props.progress)) {
            this.laddaInstance.setProgress(this.props.progress);
        }
    };
    AdaptedLaddaButton.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (prevProps.loading !== this.props.loading) {
            if (this.props.loading) {
                this.laddaInstance.start();
            }
            else if (this.props.disabled) {
                // .stop removes the attribute "disabled"
                // .disable calls .stop then adds the attribute "disabled"
                // see https://github.com/hakimel/Ladda/blob/master/js/ladda.js
                this.laddaInstance.disable();
            }
            else {
                this.laddaInstance.stop();
            }
        }
        if (prevProps.progress !== this.props.progress) {
            this.laddaInstance.setProgress(this.props.progress);
        }
    };
    AdaptedLaddaButton.prototype.componentWillUnmount = function () {
        this.laddaInstance.remove();
    };
    AdaptedLaddaButton.prototype.render = function () {
        return (React.createElement("button", __assign({}, omit(this.props, OMITTED_PROPS), { className: "ladda-button ".concat(this.props.className || ''), ref: this.setNode, disabled: this.props.disabled || this.props.loading }),
            React.createElement("span", { className: "ladda-label" }, this.props.children)));
    };
    return AdaptedLaddaButton;
}(Component));
export default AdaptedLaddaButton;
