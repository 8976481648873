import { useState } from 'react';
import localStorage from '../localStorage';
export function useLocalStorageState(localStorageItemKey, defaultValue, toSerializableMapper, fromSerializableMapper) {
    var toSerializableEffectiveMapper = toSerializableMapper || (function ($) { return $; });
    var fromSerializableEffectiveMapper = fromSerializableMapper || (function ($) { return $; });
    var _a = useState(localStorage.has(localStorageItemKey) ?
        fromSerializableEffectiveMapper(localStorage.get(localStorageItemKey)) : defaultValue), value = _a[0], setValue = _a[1];
    function setValueAndStore(newValue) {
        setValue(newValue);
        localStorage.set(localStorageItemKey, toSerializableEffectiveMapper(newValue));
    }
    return [
        value,
        setValueAndStore
    ];
}
