export function extraSaleDiscountApplicationChangeName(status) {
    switch (status) {
        case 'APPLICATION_SUBMITTED':
            return 'Wniosek wysłany';
        case 'APPLICATION_RETURNED':
            return 'Wniosek odesłany do skorygowania';
        case 'APPLICATION_TENTATIVELY_ACCEPTED':
            return 'Wniosek wstępnie zaakceptowany';
        case 'APPLICATION_ACCEPTED':
            return 'Wniosek zaakceptowany';
        case 'APPLICATION_REJECTED':
            return 'Wniosek odrzucony';
        case 'APPLICATION_IS_GIVEN_A_POSITIVE_SALES_OPINION':
        case 'APPLICATION_IS_GIVEN_A_POSITIVE_ACCOUNTING_OPINION':
            return 'Wniosek zaopiniowany pozytywnie';
        case 'APPLICATION_IS_GIVEN_A_NEGATIVE_SALES_OPINION':
        case 'APPLICATION_IS_GIVEN_A_NEGATIVE_ACCOUNTING_OPINION':
            return 'Wniosek zaopiniowany negatywnie';
        case 'NET_LIST_PRICE_CHANGED':
            return 'Wartość samochodu zmieniona';
    }
}
