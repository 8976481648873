import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { render } from 'react-dom';
import React from 'react';
import _ from 'lodash';
import { modelName } from '../common/models';
export function renderEventContent(e, readonly) {
    var el = e.el;
    var task = e.event.extendedProps;
    var tabIndex = 1;
    var description = [task.party ? "".concat(task.party, " (").concat(task.client, ")") : task.client, modelName(task.model), task.topic].filter(function (e) { return !!e; }).join(' ');
    var taskNames = {
        SCHEDULED_CALLS: 'Telefon',
        SCHEDULED_MEETINGS: 'Spotkanie',
        SCHEDULED_OTHER_CONTACTS: 'Inne',
        SCHEDULED_TEST_DRIVES: 'Jazda próbna',
        SCHEDULED_DEMO_CAR_HANDOVERS: 'Wydanie demo',
        SCHEDULED_DEMO_CAR_RETURNS: 'Zwrot demo',
        SCHEDULED_CAR_HANDOVERS: 'Wydanie samochodu'
    };
    var locations = {
        IN_SHOWROOM: 'w salonie',
        OUT_OF_SHOWROOM: 'poza salonem'
    };
    var content = (React.createElement(OverlayTrigger, { placement: "top", rootClose: true, overlay: tooltip(task.id, "(".concat(taskName(), ") ").concat(description)) },
        React.createElement("div", { className: 'calendar__event ' + (task.inactive ? 'calendar__event--filtered-out' : '' + ' ' + (readonly ? 'not-clickable' : '')), "data-scheduled-task-id": task.id, "data-scheduled-task-type": task.type, "data-scheduled-task-location": task.location, role: "button", tabIndex: tabIndex, onClick: function () { return goTo(task); } },
            React.createElement("p", null,
                React.createElement("i", { className: "icon" }),
                description))));
    render(content, el);
    function taskName() {
        return _.chain([taskNames[task.type], task.location ? locations[task.location] : null])
            .filter(function ($) { return !!$; })
            .join(' ')
            .value();
    }
    function goTo(task) {
        if (readonly) {
            return;
        }
        else if (task.type === 'SCHEDULED_CAR_HANDOVERS') {
            window.location.href = "#/orders/".concat(task.id, "/car-handover");
        }
        else {
            window.location.href = task.partyId ?
                "#/fleet-service/".concat(encodeURIComponent(task.partyId), "?scheduledContactId=").concat(task.id) :
                "#/customer-service//".concat(task.id, "?clearContext=true");
        }
    }
    function tooltip(id, text) {
        return (React.createElement(Tooltip, { id: "calendar-event-tooltip-".concat(id), "data-testid": "calendar-event-tooltip-".concat(id) }, text));
    }
}
