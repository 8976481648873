import { formatDate } from 'common/formatters/date';
import { useHttp } from 'http/httpService';
export function useAutomaticSaleFunnel() {
    var http = useHttp();
    return {
        load: function (from, to) {
            return http.get("/api/automatic-sale-funnel?from=".concat(formatDate(from), "&to=").concat(formatDate(to)))
                .then(function (response) { return response.data; });
        },
        loadForSales: function (from, to, sales) {
            var salesParams = sales.join('&ofAssistant=');
            return http.get("/api/automatic-sale-funnel?from=".concat(formatDate(from), "&to=").concat(formatDate(to), "&ofAssistant=").concat(salesParams))
                .then(function (response) { return response.data; });
        },
        loadForDealer: function (from, to, dealer) {
            return http.get("/api/automatic-sale-funnel?from=".concat(formatDate(from), "&to=").concat(formatDate(to), "&ofDealer=").concat(dealer))
                .then(function (response) { return response.data; });
        }
    };
}
