import { AbstractTask } from './abstractTask.component';
import { polishTimestamp } from 'common/formatters/date';
export function FleetCarReservationsToAcceptTask(_a) {
    var task = _a.task;
    function renterCategory(type) {
        switch (type) {
            case 'PRESS_CARS':
                return 'JOURNALIST';
            case 'FLEET_SALE_CARS':
                return 'FLEET_CUSTOMER';
            case 'PRODUCT_CARS':
                return 'PRODUCT_CUSTOMER';
            case 'VCP_DEMO_CARS':
                return 'VCP_DEMO_CUSTOMER';
        }
    }
    function reservationTypeDictionary(type) {
        if (!type) {
            return undefined;
        }
        switch (type) {
            case 'CLIENT':
                return 'Klient';
            case 'DEALER':
                return 'Dealer';
            case 'VCP_EMPLOYEE':
                return 'Pracownik VCP';
            case 'VIP':
                return 'VIP';
            case 'SERVICE':
                return 'Serwis';
            case 'EVENT':
                return 'Event';
            case 'EVENT_AGENCY':
                return 'Agencja eventowa';
            case 'JOURNALIST':
                return 'Dziennikarz';
            case 'BUSY':
                return 'Zajęty';
        }
    }
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Zaakceptuj rezerwację',
        description: "".concat(task.model, " (").concat(task.modelYear, "), ").concat(task.registrationNumber, ", ").concat(polishTimestamp(task.reservationFrom), " - ").concat(polishTimestamp(task.reservationTo), ", ").concat(task.renterName, ", ").concat(reservationTypeDictionary(task.reservationType)),
        redirectUrl: "#/fleet-cars-calendar/".concat(renterCategory(task.fleet), "?reservationId=").concat(task.reservationId),
    });
}
