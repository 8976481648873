import { AbstractTask } from './abstractTask.component';
export function DealerMarketingBudgetTargetsApplicationsTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Zaakceptuj budżet',
        description: "".concat(task.dealerName, " ").concat(task.year),
        redirectUrl: "#/dealer-marketing-budget-targets-application-acceptance/".concat(task.year, "/").concat(task.dealerId)
    });
}
