import { AbstractTask } from './abstractTask.component';
import { polishTimestamp } from '../../../common/formatters/date';
import { shortPartyDescription } from '../../../common/party';
import { modelName } from '../../../common/models';
export function ExtraSaleDiscountApplicationsToCorrectTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Skoryguj wniosek o rabat DRS',
        description: "Wniosek z dnia ".concat(polishTimestamp(task.submissionTimestamp), ", ").concat(shortPartyDescription(task.party), ", ").concat(task.car ? modelName(task.car.modelId) : '', ", ").concat(task.car ? task.car.commonOrderNumber : ''),
        redirectUrl: "#/extra-sale-discounts/submission/".concat(task.id)
    });
}
