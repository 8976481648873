import { useHttp } from 'http/httpService';
export function useTimeline() {
    var http = useHttp();
    return {
        loadForCurrentAssistant: function (from, to) {
            return http.get("/api/timeline?ofCurrentAssistant&from=".concat(from, "&to=").concat(to));
        },
        loadForAssistant: function (assistantId, from, to) {
            return http.get("/api/timeline?ofAssistant=".concat(assistantId, "&from=").concat(from, "&to=").concat(to));
        }
    };
}
