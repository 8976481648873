import { AbstractTask } from './abstractTask.component';
import { polishDate } from '../../../common/formatters/date';
export function ConcessionApplicationsToSettleTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: polishDate(task.settlementOrderDate),
        action: 'Zaakceptuj rozliczenie',
        description: "".concat(task.dealer, " ").concat(task.client),
        redirectUrl: "#/concessions/settlement-acceptance/".concat(task.id)
    });
}
