import React from 'react';
import { DateInput } from 'common/dateInput.component';
import moment from 'moment';
export function ActivityPeriodForm(_a) {
    var startActivityPeriod = _a.startActivityPeriod, applyStartDate = _a.applyStartDate, endActivityPeriod = _a.endActivityPeriod, applyEndDate = _a.applyEndDate, minimumToDate = _a.minimumToDate, endOfPeriodDefined = _a.endOfPeriodDefined, toggleEndOfActivityPeriodDefined = _a.toggleEndOfActivityPeriodDefined, minimumFromDateNewActivityPeriod = _a.minimumFromDateNewActivityPeriod;
    return (React.createElement("form", { className: "periods", id: "activity-periods", "data-testid": "new-activity-period-form" },
        React.createElement("div", { className: "date" },
            React.createElement("label", { className: "col-lg-2 control-label" }, "od:"),
            React.createElement(DateInput, { date: startActivityPeriod, setDate: applyStartDate, "data-testid": "wholesalerActivityDateFromFilter", minimumDate: moment(minimumFromDateNewActivityPeriod).toISOString() })),
        React.createElement("div", { className: "date" },
            React.createElement("label", { className: "col-lg-2 control-label" }, "do:"),
            React.createElement(DateInput, { date: endActivityPeriod, setDate: applyEndDate, "data-testid": "wholesalerActivityDateToFilter", minimumDate: minimumToDate, className: "".concat(!endOfPeriodDefined ? 'empty-date' : ''), disabled: !endOfPeriodDefined })),
        React.createElement("div", { className: "selection end-of-period-checkbox" },
            React.createElement("input", { type: "checkbox", id: "endOfPeriodDefined", "data-testid": "endOfPeriodDefinedCheckbox", checked: !endOfPeriodDefined, onChange: toggleEndOfActivityPeriodDefined }),
            React.createElement("label", { htmlFor: "endOfPeriodDefined", className: "position-label end-of-period-label" }, "nie definiuj ko\u0144ca okresu aktywno\u015Bci"))));
}
