import { AbstractTask } from './abstractTask.component';
export function PendingOwnPhoneNumberCampaignTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Wykonaj',
        description: "Kampania telefoniczna: ".concat(task.campaignName),
        redirectUrl: "#/own-phone-number-campaign/".concat(task.id)
    });
}
