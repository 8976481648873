import { useHttp } from 'http/httpService';
export function useCars() {
    var http = useHttp();
    return {
        byPartyIdentificationNumber: function (identificationNumber, from, to) {
            return http.get("/api/cars?byPartyIdentificationNumber=".concat(identificationNumber, "&from=").concat(from, "&to=").concat(to))
                .then(function (response) { return response.data; });
        }
    };
}
