// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react .extra-sale-discount-acceptance-form .form-controls-container {
  display: flex;
  flex-direction: row-reverse;
}
`, "",{"version":3,"sources":["webpack://./src/extra-sale-discounts/acceptance-form/AcceptanceForm.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,2BAAA;AAAJ","sourcesContent":[".extra-sale-discount-acceptance-form {\n  .form-controls-container {\n    display: flex;\n    flex-direction: row-reverse;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
