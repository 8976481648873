import { useHttp } from 'http/httpService';
export function useSalesList() {
    var http = useHttp();
    return {
        load: function () {
            return http.get('/api/users?withRole=SALES')
                .then(function (response) { return response.data; });
        }
    };
}
