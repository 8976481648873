export function settlementStatus(status) {
    switch (status) {
        case 'SUBMITTED':
            return 'Do rozliczenia';
        case 'SETTLED':
            return 'Rozliczony';
        case 'CORRECTION_APPLICATION_SUBMITTED':
            return 'Rozliczenie do skorygowania';
        default:
            return status;
    }
}
