import { ListValue } from 'common/components/ListValue';
import { Car } from 'extra-sale-discounts/Car';
import React from 'react';
export function PartyData(props) {
    var _a, _b, _c;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { id: "clientPartyLabel", className: "col-lg-3 control-label" }, "Strona klienta"),
                    React.createElement("div", { "aria-labelledby": "clientPartyLabel", className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static", id: "factoryOrderDate", "data-testid": "factoryOrderDate" }, (_a = props.application) === null || _a === void 0 ? void 0 : _a.party.name))))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { className: "col-lg-3 control-label" }, (_b = props.application) === null || _b === void 0 ? void 0 : _b.party.identificationNumber.type),
                    React.createElement("div", { className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static", id: "factoryOrderDate", "data-testid": "factoryOrderDate" }, (_c = props.application) === null || _c === void 0 ? void 0 : _c.party.identificationNumber.value))))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-group" },
                React.createElement("label", { id: "partyCarsLabel", htmlFor: "carsLabel", className: "col-lg-3 control-label" }, "Samochody"),
                React.createElement("div", { "aria-labelledby": "partyCarsLabel", className: "col-lg-5" },
                    React.createElement(ListValue, { "aria-labelledby": "carsLabel", elements: props.partyCars, keyBy: function (car) { return car.vin; }, entryBy: function (car) { return React.createElement(Car, { car: car }); } })))));
}
