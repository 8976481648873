export function concessionStatus(status) {
    switch (status) {
        case 'SUBMITTED':
            return 'Wysłany';
        case 'DRAFT':
            return 'Szkic';
        case 'REJECTED':
        case 'DECLINED':
            return 'Odrzucony';
        case 'ACCEPTED':
        case 'APPROVED':
            return 'Zaakceptowany';
        case 'RETURNED':
            return 'Do uzupełnienia';
        case 'TENTATIVELY_ACCEPTED':
            return 'Wstępnie zaakceptowany';
        case 'SUBMITTED_CONDITION':
            return 'Zmiana warunków';
        case 'APPROVED_CONDITION':
            return 'Zmiana warunków zaakceptowana';
        case 'DECLINED_CONDITION':
            return 'Zmiana warunków odrzucona';
        case 'APPROVED_DURATION_EXTENSION':
            return 'Zmiana daty ważności zaakceptowana';
        case 'DECLINED_DURATION_EXTENSION':
            return 'Zmiana daty ważności odrzucona';
        case 'SUBMITTED_DURATION_EXTENSION':
            return 'Zmiana daty ważności';
        case 'BUSINESS_DURATION_EXTENSION':
            return 'Zmiana daty ważności VCP';
        case 'WITHDRAWN_RECKONING':
            return 'Zamówienie wycofane';
        case 'RECKONING_CORRECTED':
        case 'ADJUSTED':
            return 'Rozliczenie skorygowane';
        default:
            return status;
    }
}
