var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import DatePicker from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
var polishLocale = {
    // months list by order
    months: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień',
    ],
    // week days by order
    weekDays: [
        {
            name: 'Poniedziałek',
            short: 'Pn',
        },
        {
            name: 'Wtorek',
            short: 'Wt',
        },
        {
            name: 'Środa',
            short: 'Śr',
        },
        {
            name: 'Czwartek',
            short: 'Cz',
        },
        {
            name: 'Piątek',
            short: 'Pt',
        },
        {
            name: 'Sobota',
            short: 'Sb',
            isWeekend: true,
        },
        {
            name: 'Niedziela', // used for accessibility
            short: 'Nd', // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        }
    ],
    // just play around with this number between 0 and 6
    weekStartingIndex: 6,
    // return a { year: number, month: number, day: number } object
    getToday: function (gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate: function (date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength: function (date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit: function (digit) {
        return digit;
    },
    // texts in the date picker
    nextMonth: 'Następny miesiąc',
    previousMonth: 'Poprzedni miesiąc',
    openMonthSelector: 'Otwórz wybór miesiąca',
    openYearSelector: 'Otwórz wybór roku',
    closeMonthSelector: 'Zamknij wybór miesiąca',
    closeYearSelector: 'Zamknij wybór roku',
    defaultPlaceholder: 'Wybierz...',
    // for input range value
    from: 'od',
    to: 'do',
    // used for input value when multi dates are selected
    digitSeparator: ',',
    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,
    // is your language rtl or ltr?
    isRtl: false,
};
export function DateInput(_a) {
    var date = _a.date, setDate = _a.setDate, minimumDate = _a.minimumDate, maximumDate = _a.maximumDate, disabled = _a.disabled, props = __rest(_a, ["date", "setDate", "minimumDate", "maximumDate", "disabled"]);
    var _b = useState(toDay(date)), day = _b[0], setDay = _b[1];
    useEffect(function () {
        setDay(toDay(date));
    }, [date]);
    function toDay(value) {
        if (!value) {
            return undefined;
        }
        var date = moment(value);
        return { year: date.year(), month: date.month() + 1, day: date.date() };
    }
    function formatDay(value) {
        return "".concat(value.year, "-").concat(value.month < 10 ? '0' : '').concat(value.month, "-").concat(value.day < 10 ? '0' : '').concat(value.day);
    }
    function renderInput(_a) {
        var ref = _a.ref;
        return (React.createElement("button", { type: "button", className: "btn btn-default", ref: ref, disabled: disabled },
            React.createElement("span", { className: "far fa-calendar" }),
            "\u00A0",
            React.createElement("span", { "data-testid": "input-data-value" }, formatDay(day))));
    }
    return (React.createElement("span", __assign({}, props),
        React.createElement(DatePicker, { locale: polishLocale, minimumDate: toDay(minimumDate), maximumDate: toDay(maximumDate), renderInput: renderInput, onChange: function ($) {
                setDay($);
                setDate(formatDay($));
            }, value: day, colorPrimary: "#5A91BB" })));
}
