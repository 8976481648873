import _ from 'lodash';
import { modelName } from '../models';
export function carDefinitionWithStockCategoryDescription(carDefinition) {
    return carDefinition && [stockCategory(carDefinition.stockCategory), foundCarDefinitionDescription(carDefinition)]
        .filter(_.identity)
        .join(', ');
}
export function stockCategory(stockCategory) {
    switch (stockCategory) {
        case 'OWN_STOCK':
            return 'Dealera';
        case 'OTHER_DEALERS_STOCK':
            return 'Innego dealera';
        case 'IMPORTER_STOCK':
            return 'Importera';
        default:
            return stockCategory;
    }
}
export function foundCarDefinitionDescription(carDefinition) {
    return carDefinition && [modelName(carDefinition.modelId),
        'CON: ' + carDefinition.commonOrderNumber,
        carDefinition.dealerOrder && 'DO: ' + carDefinition.dealerOrder,
        carDefinition.vin,
        carDefinition.registrationNumber]
        .filter(_.identity)
        .join(', ');
}
