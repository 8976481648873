var LocalStorage = /** @class */ (function () {
    function LocalStorage() {
    }
    LocalStorage.prototype.set = function (key, value) {
        if (value === undefined) {
            this.remove(key);
        }
        else {
            sessionStorage.setItem(this.keyName(key), JSON.stringify(value));
        }
    };
    LocalStorage.prototype.get = function (key) {
        return JSON.parse(sessionStorage.getItem(this.keyName(key)));
    };
    LocalStorage.prototype.has = function (key) {
        return !!sessionStorage.getItem(this.keyName(key));
    };
    LocalStorage.prototype.remove = function (key) {
        sessionStorage.removeItem(this.keyName(key));
    };
    LocalStorage.prototype.keyName = function (key) {
        return 'ls.' + key;
    };
    return LocalStorage;
}());
export default new LocalStorage();
