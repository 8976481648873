import { AbstractTask } from './abstractTask.component';
export function ClientsAssistantAssignmentTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Przydziel opiekuna',
        description: task.name,
        redirectUrl: "#/client-assistant-assignment/".concat(task.id)
    });
}
