import { useHttp } from 'http/httpService';
export function usePartsWholesaleWhitelistedParties() {
    var http = useHttp();
    return {
        getParties: function (organizationId) {
            return http
                .get("/api/parts-wholesale-parties-whitelist?organizationId=".concat(organizationId))
                .then(function (response) { return response.data; });
        },
        getAllParties: function () {
            return http
                .get('/api/parts-wholesale-parties-whitelist/get-all-parties')
                .then(function (response) { return response.data; });
        },
        getDealers: function () {
            return http
                .get('/api/organizations?dealers')
                .then(function (response) { return response.data; });
        },
        setLocalSuspension: function (nip, suspended, organizationId) {
            return http.post("/api/parts-wholesale-parties-whitelist/set-suspension?nip=".concat(nip), {
                suspended: suspended,
                type: 'local',
                organizationId: organizationId
            });
        },
        setGlobalSuspension: function (nip, suspended) {
            return http.post("/api/parts-wholesale-parties-whitelist/set-suspension?nip=".concat(nip), {
                suspended: suspended,
                type: 'global'
            });
        }
    };
}
