// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react .wholesaler-data {
  display: flex;
  width: 65%;
  padding-top: 20px;
  gap: 27px;
}
#react .wholesaler-data .wholesaler-nip-label {
  white-space: nowrap;
}
#react .wholesaler-data .wholesaler-name {
  padding-left: 13px;
}
#react .new-period-message {
  font-weight: 600;
  margin-top: 20px;
  color: #777777;
  margin-left: 60px;
}
#react .period {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
#react .period p {
  margin-bottom: 0;
  padding: 10px 8px;
}
#react .list-value {
  width: 33%;
}
#react .pencil-button {
  border: none;
  background-color: inherit;
}
#react .period p.periodFrom {
  padding-right: 20px;
}
#react .period p.periodTo {
  padding-left: 20px;
}
#react .activity-periods {
  padding-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/parts-wholesale-whitelisted-parties/partWholesaler.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,UAAA;EACA,iBAAA;EACA,SAAA;AACJ;AALA;EAOQ,mBAAA;AACR;AARA;EAWQ,kBAAA;AAAR;AAIA;EACI,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;AAFJ;AAKA;EACI,0BAAA;EACA,6BAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AAHJ;AAMA;EACI,gBAAA;EACA,iBAAA;AAJJ;AAOA;EACI,UAAA;AALJ;AAQA;EACI,YAAA;EACA,yBAAA;AANJ;AASA;EACI,mBAAA;AAPJ;AAUA;EACI,kBAAA;AARJ;AAWA;EACI,iBAAA;AATJ","sourcesContent":[".wholesaler-data {\n    display: flex;\n    width: 65%;\n    padding-top: 20px;\n    gap: 27px;\n\n    .wholesaler-nip-label {\n        white-space: nowrap;\n    }\n\n    .wholesaler-name {\n        padding-left: 13px;\n    }\n}\n\n.new-period-message {\n    font-weight: 600;\n    margin-top: 20px;\n    color: #777777;\n    margin-left: 60px;\n}\n\n.period {\n    border-top: 1px solid grey;\n    border-bottom: 1px solid grey;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.period p {\n    margin-bottom: 0;\n    padding: 10px 8px;\n}\n\n.list-value {\n    width: 33%;\n}\n\n.pencil-button {\n    border: none;\n    background-color: inherit;\n}\n\n.period p.periodFrom {\n    padding-right: 20px;\n}\n\n.period p.periodTo {\n    padding-left: 20px;\n}\n\n.activity-periods {\n    padding-top: 30px;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
