import { useHttp } from 'http/httpService';
export function useClientParties() {
    var http = useHttp();
    return {
        load: function (clientId) {
            return http.get('/api/clients/' + clientId + '/parties?withIdentificationNumber')
                .then(function (response) { return response.data; });
        }
    };
}
