import { AbstractTask } from './abstractTask.component';
export function ConcessionApplicationsSettlementCorrectionApplicationsToAcceptTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Skoryguj rozliczenie',
        description: "".concat(task.dealer, " ").concat(task.dealerOrder),
        redirectUrl: "#/concessions/settlement-correction-application-acceptance/".concat(task.concessionApplicationId, "/").concat(task.orderId)
    });
}
