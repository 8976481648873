import { AbstractTask } from './abstractTask.component';
export function ConcessionApplicationSupplementsToAcceptTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Rozszerz rabat VCP',
        description: "".concat(task.dealer, " ").concat(task.client),
        redirectUrl: "#/concessions/supplement-acceptance/".concat(task.concessionApplicationId, "/").concat(task.supplementId)
    });
}
