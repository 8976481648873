import { AbstractTask } from './abstractTask.component';
export function UsedCarsLeadToPickUpTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Podejmij',
        description: "Leady na samochody u\u017Cywane: ".concat(task.count),
        redirectUrl: '#/used-cars-leads'
    });
}
