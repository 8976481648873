import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useExtraSaleDiscounts } from '../extraSaleDiscounts';
import { SubmissionForm } from './SubmissionForm.component';
import './SubmissionForm.less';
export var EditSubmissionForm = withCancellation(function () {
    var extraSaleDiscounts = useExtraSaleDiscounts();
    var _a = useState(null), application = _a[0], setApplication = _a[1];
    var applicationId = useParams().applicationId;
    useEffect(function () {
        extraSaleDiscounts.load(applicationId)
            .then(function (data) {
            setApplication(data);
        })
            .catch(function () { });
    }, []);
    return React.createElement(SubmissionForm, { application: application });
});
