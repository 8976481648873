import { useFiles } from 'common/components/FileUploadForm';
import { ListValue } from 'common/components/ListValue';
import { carDefinitionWithStockCategoryDescription } from 'common/formatters/carDefinition';
import { polishDate } from 'common/formatters/date';
import { formatNumber } from 'common/formatters/number';
import { PolishZloty } from 'common/formatters/PolishZloty';
import userContext from 'common/userContext';
import { ChangesHistory } from 'extra-sale-discounts/changesHistory';
import { selectedFundingFormDescription } from 'extra-sale-discounts/selectedFundingFormDescription';
import _ from 'lodash';
import React from 'react';
import './applicationData.less';
export function ApplicationData(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var files = useFiles();
    return React.createElement("div", { className: "extra-sale-discount-application-data" },
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { id: "applicationNumberLabel", className: "control-label col-lg-3" }, "Numer"),
                    React.createElement("div", { "aria-labelledby": "applicationNumberLabel", className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static", "data-testid": "applicationNumber" }, (_a = props.application) === null || _a === void 0 ? void 0 : _a.number))))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { id: "selectedCarLabel", className: "control-label col-lg-3" }, "Samoch\u00F3d"),
                    React.createElement("div", { "aria-labelledby": "selectedCarLabel", className: "col-lg-6" },
                        React.createElement("div", null,
                            React.createElement("span", { className: "form-control-static" }, carDefinitionWithStockCategoryDescription((_b = props.application) === null || _b === void 0 ? void 0 : _b.car))),
                        ((_c = props.application) === null || _c === void 0 ? void 0 : _c.car.invoiceDate)
                            ? React.createElement("div", { className: "text-info" }, "Ten samoch\u00F3d zosta\u0142 ju\u017C zafakturowany przez VCP")
                            : null)))),
        props.showChangesHistory ? React.createElement(ChangesHistory, { changes: (_d = props.application) === null || _d === void 0 ? void 0 : _d.changes }) : null,
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-6 no-right-padding" },
                    React.createElement("label", { id: "factoryOrderDateLabel", className: "control-label col-lg-6" }, "Data zam\u00F3wienia VISTA"),
                    React.createElement("div", { "aria-labelledby": "factoryOrderDateLabel", className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static" }, polishDate((_e = props.application) === null || _e === void 0 ? void 0 : _e.car.factoryOrderDate)))),
                React.createElement("div", { className: "form-group col-lg-6" },
                    React.createElement("label", { id: "carNetListPriceLabel", className: "control-label col-lg-6" }, "Cena katalogowa netto"),
                    React.createElement("div", { "aria-labelledby": "carNetListPriceLabel", className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static" },
                            React.createElement(PolishZloty, null, (_f = props.application) === null || _f === void 0 ? void 0 : _f.car.netListPrice)))))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-6 no-right-padding" },
                    React.createElement("label", { id: "deliveryDateLabel", className: "control-label col-lg-6" }, "Data dostawy"),
                    React.createElement("div", { className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static", id: "deliveryDate" }, polishDate((_g = props.application) === null || _g === void 0 ? void 0 : _g.car.deliveryDate)))),
                React.createElement("div", { className: "form-group col-lg-6" },
                    React.createElement("label", { id: "carGrossListPriceLabel", className: "control-label col-lg-6" }, "Cena katalogowa brutto"),
                    React.createElement("div", { "aria-labelledby": "carGrossListPriceLabel", className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static" },
                            React.createElement(PolishZloty, null, props.carGrossListPrice)))))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { id: "orderTypeLabel", className: "control-label col-lg-3" }, "Typ zam\u00F3wienia"),
                    React.createElement("div", { className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static", id: "orderType" }, (_h = props.application) === null || _h === void 0 ? void 0 : _h.car.detailedTypeCodeDescription))))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { className: "col-lg-3 control-label" }, "Data wydania na umowie"),
                    React.createElement("div", { className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static", id: "plannedHandoverDate" }, polishDate((_j = props.application) === null || _j === void 0 ? void 0 : _j.car.plannedHandoverDate)))))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { className: "col-lg-3 control-label" }, "Data wydania w DOL"),
                    React.createElement("div", { className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static", id: "plannedHandoverDate" }, polishDate((_k = props.application) === null || _k === void 0 ? void 0 : _k.car.handoverDate)))))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { id: "fundingFormLabel", className: "col-lg-3 control-label" }, "Rodzaj finansowania"),
                    React.createElement("div", { className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static", "aria-labelledby": "fundingFormLabel" }, selectedFundingFormDescription((_l = props.application) === null || _l === void 0 ? void 0 : _l.funding)))))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { id: "applicationReasonsLabel", className: "col-lg-3 control-label" }, "Powody zg\u0142oszenia"),
                    React.createElement("div", { "aria-labelledby": "applicationReasonsLabel", className: "col-lg-9" }, props.editableApplicationReasons() ?
                        React.createElement(ApplicationReasons, { reasons: ((_m = props.application) === null || _m === void 0 ? void 0 : _m.applicationReasons.selected) || [], carNetListPrice: (_o = props.application) === null || _o === void 0 ? void 0 : _o.car.netListPrice, changeApplicationReasonDiscountType: props.changeApplicationReasonDiscountType, showDiscountTypes: true }) :
                        React.createElement(ApplicationReasons, { reasons: ((_p = props.application) === null || _p === void 0 ? void 0 : _p.applicationReasons.selected) || [], carNetListPrice: (_q = props.application) === null || _q === void 0 ? void 0 : _q.car.netListPrice, showDiscountTypes: props.showDiscountTypes }))))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { className: "col-lg-3 control-label" }, "Komentarz"),
                    React.createElement("div", { className: "col-lg-6" },
                        React.createElement("span", { className: "form-control-static", id: "comment" }, (_r = props.application) === null || _r === void 0 ? void 0 : _r.comment))))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-inline" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { id: "attachmentsLabel", className: "col-lg-3 control-label" }, "Za\u0142\u0105czniki"),
                    React.createElement("div", { className: "col-lg-6" },
                        React.createElement(ListValue, { "aria-labelledby": "attachmentsLabel", elements: (_s = props.application) === null || _s === void 0 ? void 0 : _s.attachments, keyBy: function ($) { return $.location; }, entryBy: function ($) { return React.createElement("a", { role: "button", tabIndex: 0, onClick: function () { return files.openLocation({
                                    location: $.location,
                                    name: $.fileName
                                }); } }, $.fileName); } }))))));
}
function ApplicationReasons(props) {
    function totalRequestedNetDiscount() {
        return _.chain(props.reasons).map(function ($) { return $.requestedNetDiscount; }).sum().value();
    }
    function isFromVCP() {
        return userContext.isFromVCP();
    }
    var discountTypes = [
        { id: 'DRS1', name: 'DRS1' },
        { id: 'DRS2', name: 'DRS2' },
        { id: 'DRS3', name: 'DRS3' }
    ];
    return React.createElement("table", { className: "table table-striped" },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", null, "Pow\u00F3d"),
                React.createElement("th", null, "Oczekiwana kwota"),
                React.createElement("th", null, "Warto\u015B\u0107 rabatu (%)"),
                props.showDiscountTypes ? React.createElement("th", null, "Rodzaj rabatu") : null)),
        React.createElement("tbody", null,
            props.reasons.map(function (applicationReason) {
                return React.createElement("tr", { className: "position", key: applicationReason.id, "data-testid": "reason-".concat(applicationReason.id) },
                    React.createElement("td", { className: "selection checkbox" },
                        React.createElement("span", { className: "form-control-static" }, applicationReason.name)),
                    React.createElement("td", { className: "selection selection-input" },
                        React.createElement("span", { className: "form-control-static" },
                            React.createElement(PolishZloty, null, applicationReason.requestedNetDiscount))),
                    React.createElement("td", { className: "selection selection-value" },
                        React.createElement("span", { className: "form-control-static", "data-testid": "discountPercent" }, formatNumber(applicationReason.requestedNetDiscount / props.carNetListPrice * 100))),
                    props.showDiscountTypes ?
                        React.createElement("td", null, props.changeApplicationReasonDiscountType ?
                            React.createElement("select", { className: "form-control", onChange: function (event) { return props.changeApplicationReasonDiscountType(applicationReason.id, event.target.value); }, value: applicationReason.discountType },
                                React.createElement("option", { value: "" }, "Wybierz"),
                                discountTypes.map(function (discountType) {
                                    return React.createElement("option", { value: discountType.id, key: discountType.id }, discountType.name);
                                })) :
                            React.createElement("span", { className: "form-control-static" }, applicationReason.discountType)) : null);
            }),
            React.createElement("tr", null,
                React.createElement("td", { className: "selection selection-value" },
                    React.createElement("label", { className: "form-control-static" }, "Suma")),
                React.createElement("td", { className: "selection selection-value" },
                    React.createElement("span", { className: "form-control-static", "data-testid": "requestedNetDiscountTotal" },
                        React.createElement(PolishZloty, null, totalRequestedNetDiscount()))),
                React.createElement("td", { className: "selection selection-value" },
                    React.createElement("span", { className: "form-control-static", "data-testid": "discountPercentTotal" }, formatNumber(totalRequestedNetDiscount() / props.carNetListPrice * 100))),
                props.showDiscountTypes ? React.createElement("td", null) : null)));
}
