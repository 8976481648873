import { AbstractTask } from './abstractTask.component';
export function CorrectingInvoiceApplicationsToHandleTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Skoryguj faktury',
        description: "Wnioski: ".concat(task.count),
        redirectUrl: '#/invoice-application/summary?applicationType=CORRECTIONS'
    });
}
