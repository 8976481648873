// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react .period-item {
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
  border-bottom: 1px solid grey;
}
#react .period-item p {
  margin-bottom: 0;
}
#react .period-item:first-child {
  border-top: 1px solid grey;
}
#react .editing-period-item {
  padding: 4px 10px;
}
#react .period-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 33%;
  margin-top: 20px;
  margin-left: 20px;
}
#react .period-to-edit {
  display: flex;
}
#react .period-to-edit .empty-end-date .btn span:not(.fa-calendar) {
  opacity: 0;
}
#react .period-to-edit > .date > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}
#react .DatePicker button {
  padding: 4px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/parts-wholesale-whitelisted-parties/periodsList.less"],"names":[],"mappings":"AAAA;EACG,aAAA;EACA,mBAAA;EACA,YAAA;EACA,8BAAA;EACA,6BAAA;AACH;AANA;EAQO,gBAAA;AACP;AAGA;EACI,0BAAA;AADJ;AAIA;EACI,iBAAA;AAFJ;AAKA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA;EACA,gBAAA;EACA,iBAAA;AAHJ;AAMA;EACI,aAAA;AAJJ;AAGA;EAIQ,UAAA;AAJR;AAQE;EACE,kBAAA;EACA,iBAAA;AANJ;AASE;EACI,iBAAA;AAPN","sourcesContent":[".period-item {\n   display: flex;\n   align-items: center;\n   padding: 8px;\n   justify-content: space-between;\n   border-bottom: 1px solid grey;\n\n   p {\n       margin-bottom: 0;\n   }\n}\n\n.period-item:first-child {\n    border-top: 1px solid grey;\n}\n\n.editing-period-item {\n    padding: 4px 10px;\n}\n\n.period-list {\n    list-style: none;\n    display: flex;\n    flex-direction: column;\n    width: 33%;\n    margin-top: 20px;\n    margin-left: 20px;\n}\n\n.period-to-edit {\n    display: flex;\n\n    .empty-end-date .btn span:not(.fa-calendar) {\n        opacity: 0;\n    }\n  }\n\n  .period-to-edit > .date > [class*='col-'] {\n    padding-right: 5px;\n    padding-left: 5px;\n  }\n\n  .DatePicker button {\n      padding: 4px 10px;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
