import { FileUploadForm } from 'common/components/FileUploadForm';
import { ListValue } from 'common/components/ListValue';
import SearchingSelect from 'common/components/SearchingSelect.component';
import { DateInput } from 'common/dateInput.component';
import { carDefinitionWithStockCategoryDescription } from 'common/formatters/carDefinition';
import { parseDate, polishDate } from 'common/formatters/date';
import { discountName } from 'common/formatters/discountName';
import { fundingName } from 'common/formatters/fundingName';
import { PolishZloty } from 'common/formatters/PolishZloty';
import { settlementStatus } from 'common/formatters/settlementStatus';
import { shortModelName } from 'common/models';
import { partyDescription } from 'common/party';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import { Car } from 'extra-sale-discounts/Car';
import { ChangesHistory } from 'extra-sale-discounts/changesHistory';
import { extraSaleDiscountStatusName } from 'extra-sale-discounts/extraSaleDiscountStatus';
import { roundedPercentage } from 'extra-sale-discounts/roundedPercentage';
import { useSubmissionForm } from 'extra-sale-discounts/submission-form/useSubmissionForm';
import { UserCard } from 'extra-sale-discounts/UserCard';
import { ViewSection } from 'extra-sale-discounts/ViewSection';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import './SubmissionForm.less';
export var SubmissionForm = withCancellation(function (_a) {
    var application = _a.application;
    var _b = useSubmissionForm(application), applicant = _b.applicant, setSelectedPartyId = _b.setSelectedPartyId, selectedPartyId = _b.selectedPartyId, selectedParty = _b.selectedParty, partyCars = _b.partyCars, availableParties = _b.availableParties, applicationDraft = _b.applicationDraft, carNetListPrice = _b.carNetListPrice, setCarNetListPrice = _b.setCarNetListPrice, carGrossListPrice = _b.carGrossListPrice, applicationReasons = _b.applicationReasons, carsSalePotential = _b.carsSalePotential, setCarsSalePotential = _b.setCarsSalePotential, comment = _b.comment, setComment = _b.setComment, setFunding = _b.setFunding, plannedHandoverDate = _b.plannedHandoverDate, setPlannedHandoverDate = _b.setPlannedHandoverDate, carDefinition = _b.carDefinition, settlement = _b.settlement, loading = _b.loading, concessions = _b.concessions, partyExtraSaleDiscounts = _b.partyExtraSaleDiscounts, loadingPartyExtraSaleDiscount = _b.loadingPartyExtraSaleDiscount, submitApplication = _b.submitApplication, searchCars = _b.searchCars, loadCar = _b.loadCar, attachments = _b.attachments, setAttachments = _b.setAttachments, isNew = _b.isNew;
    return React.createElement("article", { className: "extra-sale-discount-submission-form", style: { marginBottom: '20px' } },
        React.createElement(ViewSection, { title: "Wnioskuj\u0105cy", "data-testid": "applicantSection" },
            React.createElement(UserCard, { user: applicant })),
        React.createElement(ViewSection, { title: "Zamawiaj\u0105cy", "data-testid": "partySection" },
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { htmlFor: "partySelect", className: "col-lg-3 control-label" }, "Strona klienta"),
                    React.createElement("div", { className: "col-lg-5" }, isNew() ?
                        (React.createElement("select", { "data-testid": "partySelectInput", id: "partySelect", className: "form-control", onChange: function (event) { return setSelectedPartyId(event.target.value); }, value: selectedPartyId }, availableParties.map(function (option) {
                            return React.createElement("option", { value: option.value.id, key: option.value.id }, option.label);
                        }))) :
                        (React.createElement("div", { className: "form-control-static", "data-testid": "partyName" }, availableParties
                            .filter(function (party) { return party.value.id === selectedPartyId; })
                            .map(function (party) { return party.label; })))))),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { id: "carsLabel", htmlFor: "cars", className: "col-lg-3 control-label" }, "Samochody"),
                    React.createElement("div", { className: "col-lg-5" },
                        React.createElement(ListValue, { "aria-labelledby": "carsLabel", elements: partyCars, keyBy: function (car) { return car.vin; }, entryBy: function (car) { return React.createElement(Car, { car: car }); } }))))),
        React.createElement(ViewSection, { title: "Potencja\u0142 sprzeda\u017Cowy" },
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { htmlFor: "carsSalePotential", className: "control-label col-lg-3" }, "Liczba samochod\u00F3w"),
                    React.createElement("div", { className: "col-lg-2" },
                        React.createElement("input", { type: "number", className: "form-control", id: "carsSalePotential", value: carsSalePotential, onChange: function (event) { return setCarsSalePotential(Number.parseInt(event.target.value)); }, "data-testid": "carsSalePotentialInput" }))))),
        React.createElement(ViewSection, { title: "Rabat DRS", "data-testid": "applicationSection" },
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { className: "col-lg-3 control-label" }, "Samoch\u00F3d"),
                    isNew() ?
                        (React.createElement("div", { className: "col-lg-9", "data-testid": "carSearch" },
                            React.createElement(SearchingSelect, { placeholder: "DO, CON, VIN, Numer rejestracyjny", loadOptions: searchCars, getOptionLabel: carDefinitionWithStockCategoryDescription, getOptionValue: function ($) { return $.commonOrderNumber; }, onChange: function ($) { return loadCar($.commonOrderNumber); } })))
                        :
                            (React.createElement("div", { className: "col-lg-9 form-control-static", "data-testid": "carLabel" }, carDefinitionWithStockCategoryDescription(application === null || application === void 0 ? void 0 : application.car))))),
            React.createElement(ChangesHistory, { changes: application === null || application === void 0 ? void 0 : application.changes }),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-inline" },
                    React.createElement("div", { className: "form-group col-lg-6 no-right-padding" },
                        React.createElement("label", { htmlFor: "factoryOrderDate", className: "control-label col-lg-6" }, "Data zam\u00F3wienia VISTA"),
                        React.createElement("div", { className: "col-lg-6" },
                            React.createElement("span", { className: "form-control-static", id: "factoryOrderDate", "data-testid": "factoryOrderDate" }, polishDate(carDefinition === null || carDefinition === void 0 ? void 0 : carDefinition.factoryOrderDate)))),
                    React.createElement("div", { className: "form-group col-lg-6" },
                        React.createElement("label", { htmlFor: "carNetListPrice", className: "control-label col-lg-6" }, "Cena katalogowa netto"),
                        React.createElement("div", { className: "col-lg-6" },
                            React.createElement("input", { type: "number", className: "form-control", id: "carNetListPrice", value: carNetListPrice, onChange: function (event) { return setCarNetListPrice(event.target.value && Number.parseFloat(event.target.value)); }, "data-testid": "netListPriceInput" }))))),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-inline" },
                    React.createElement("div", { className: "form-group col-lg-6 no-right-padding" },
                        React.createElement("label", { htmlFor: "deliveryDate", className: "control-label col-lg-6" }, "Data dostawy"),
                        React.createElement("div", { className: "col-lg-6" },
                            React.createElement("span", { className: "form-control-static", id: "deliveryDate", "data-testid": "deliveryDate" }, polishDate(carDefinition === null || carDefinition === void 0 ? void 0 : carDefinition.deliveryDate)))),
                    React.createElement("div", { className: "form-group col-lg-6" },
                        React.createElement("label", { htmlFor: "carGrossListPrice", className: "control-label col-lg-6" }, "Cena katalogowa brutto"),
                        React.createElement("div", { className: "col-lg-6" },
                            React.createElement("span", { className: "form-control-static", id: "carGrossListPrice", "data-testid": "carGrossListPrice" },
                                React.createElement(PolishZloty, null, carGrossListPrice)))))),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-inline" },
                    React.createElement("div", { className: "form-group col-lg-12" },
                        React.createElement("label", { htmlFor: "orderType", className: "control-label col-lg-3" }, "Typ zam\u00F3wienia"),
                        React.createElement("div", { className: "col-lg-6" },
                            React.createElement("span", { className: "form-control-static", id: "orderType", "data-testid": "orderType" }, carDefinition === null || carDefinition === void 0 ? void 0 : carDefinition.detailedTypeCodeDescription))))),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { className: "col-lg-3 control-label" }, "Data wydania na umowie"),
                    React.createElement("div", { className: "col-lg-6" },
                        React.createElement(DateInput, { date: plannedHandoverDate.toISOString(), setDate: function (date) { return setPlannedHandoverDate(parseDate(date)); }, "data-testid": "plannedHandoverDatePicker" })))),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-inline" },
                    React.createElement("div", { className: "form-group col-lg-12" },
                        React.createElement("label", { htmlFor: "orderType", className: "control-label col-lg-3" }, "Data wydania w DOL"),
                        React.createElement("div", { className: "col-lg-6" },
                            React.createElement("span", { className: "form-control-static", id: "dolHandoverDate", "data-testid": "dolHandoverDate" }, carDefinition === null || carDefinition === void 0 ? void 0 : carDefinition.handoverDate))))),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { id: "fundingTypeLabel", className: "col-lg-3 control-label" }, "Rodzaj finansowania"),
                    React.createElement("div", { "aria-labelledby": "fundingTypeLabel", className: "col-lg-6" },
                        React.createElement(FundingSelector, { funding: applicationDraft.funding, onFundingChanged: setFunding })))),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { className: "col-lg-3 control-label" }, "Powody zg\u0142oszenia"),
                    React.createElement("div", { className: "col-lg-9", style: { paddingLeft: 0 } },
                        React.createElement(ReasonSelector, { reasons: applicationReasons, carNetListPrice: carNetListPrice })))),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { id: "attachments", className: "col-lg-3 control-label" }, "Za\u0142\u0105czniki"),
                    React.createElement("div", { "aria-labelledby": "attachments", className: "col-lg-5" },
                        React.createElement(FileUploadForm, { multi: true, value: attachments, onChange: setAttachments })))),
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-group col-lg-12" },
                    React.createElement("label", { className: "col-lg-3 control-label" }, "Komentarz*"),
                    React.createElement("div", { className: "col-lg-5" },
                        React.createElement("textarea", { className: "form-control", "data-testid": "commentInput", value: comment, onChange: function (event) { return setComment(event.target.value); } }),
                        React.createElement("span", null, "*pole obowi\u0105zkowe"))))),
        React.createElement(ViewSection, { title: 'Aktywne rabaty VCP dla strony: ' + partyDescription(selectedParty), "data-testid": "importerDiscountsSection" },
            React.createElement("div", { className: "form-horizontal col-lg-12" },
                React.createElement("div", { className: "form-inline" },
                    React.createElement("div", { className: "form-group col-lg-12" },
                        React.createElement("div", { className: "col-lg-12" },
                            React.createElement("table", { className: "table table-striped" },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, "Numer"),
                                        React.createElement("th", null, "Rabat wa\u017Cny do"))),
                                React.createElement("tbody", null, concessions.map(function (concession) {
                                    return React.createElement("tr", { key: concession.id },
                                        React.createElement("td", null, concession.number),
                                        React.createElement("td", null, polishDate(concession.validTo)));
                                })))))))),
        React.createElement(ViewSection, { title: "Rozliczenie rabatu VCP samochodu: CON".concat((carDefinition.commonOrderNumber && !loading) ? (" ".concat(carDefinition.commonOrderNumber)) : ''), "data-testid": "settlement-section" }, function () {
            if (loading) {
                return (React.createElement("div", null));
            }
            else if (settlement) {
                return (React.createElement("table", { "data-testid": "settlement", className: "table table-striped table-hover" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { rowSpan: 2 }, "Data zam\u00F3wienia"),
                            React.createElement("th", { rowSpan: 2 }, "Oferta specjalna/Grupa rabatowa VCP"),
                            React.createElement("th", { style: { width: '12%' }, rowSpan: 2 }, "Cena katalogowa netto"),
                            React.createElement("th", { rowSpan: 2 }, "Rabat klienta netto"),
                            React.createElement("th", { style: { width: '12%' }, colSpan: 2, className: "text-center" }, "Rabat VCP"),
                            React.createElement("th", { rowSpan: 2 }, "Finansowanie"),
                            React.createElement("th", { rowSpan: 2 }, "Status")),
                        React.createElement("tr", null,
                            React.createElement("th", null, "%"),
                            React.createElement("th", null, "kwota netto"))),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", { "data-testid": "orderDate" }, polishDate(settlement.orderDate)),
                            React.createElement("td", { "data-testid": "discountGroup" }, discountName(settlement.discount)),
                            React.createElement("td", { "data-testid": "listPrice" },
                                React.createElement(PolishZloty, null, settlement.listPrice)),
                            React.createElement("td", { "data-testid": "discount" },
                                React.createElement(PolishZloty, null, settlement.listPrice - settlement.finalPrice)),
                            React.createElement("td", { "data-testid": "producerPercentDiscount" }, percentage(settlement.producerPercentDiscount)),
                            React.createElement("td", { "data-testid": "producerDiscount" },
                                React.createElement(PolishZloty, null, producerDiscount(settlement.producerPercentDiscount, settlement.listPrice))),
                            React.createElement("td", { "data-testid": "funding" }, fundingName(settlement.funding)),
                            React.createElement("td", { "data-testid": "status" }, settlementStatus(settlement.status))))));
            }
            else if (carDefinition.commonOrderNumber && !loading) {
                return (React.createElement("div", { "data-testid": "no-settlement-info" }, "Rozliczenie rabatu VCP dla tego samochodu nie istnieje"));
            }
        }()),
        React.createElement(ViewSection, { title: "Rabaty DRS dla strony: ".concat(partyDescription(selectedParty)), "data-testid": "extra-sale-discounts-for-party-section" }, function () {
            if (loadingPartyExtraSaleDiscount) {
                return React.createElement("div", null);
            }
            else if (partyExtraSaleDiscounts.length === 0 && !loadingPartyExtraSaleDiscount) {
                return (React.createElement("div", { "data-testid": "no-extra-sale-discounts-for-party-info" }, "Rabaty DRS dla tej strony nie istniej\u0105"));
            }
            else {
                return (React.createElement("div", { className: "form-horizontal col-lg-12" },
                    React.createElement("div", { className: "form-inline" },
                        React.createElement("div", { className: "form-group col-lg-12" },
                            React.createElement("div", { className: "col-lg-12" },
                                React.createElement("table", { className: "table table-striped table-hover" },
                                    React.createElement("thead", null,
                                        React.createElement("tr", null,
                                            React.createElement("th", null, "Dealer"),
                                            React.createElement("th", null, "Samoch\u00F3d"),
                                            React.createElement("th", null, "Kwota rabatu netto"),
                                            React.createElement("th", null, "Status"))),
                                    React.createElement("tbody", { "data-testid": "extra-sale-discounts-for-party-rows" }, partyExtraSaleDiscounts.map(function (discount) {
                                        return (React.createElement("tr", { key: discount.id, "data-testid": 'extra-sale-discounts-for-party-row-' + discount.id },
                                            React.createElement("td", { "data-testid": "organization-name" }, discount.dealerName),
                                            React.createElement("td", { "data-testid": "model-id" }, "".concat(shortModelName(discount.car.modelId), " (CON ").concat(discount.car.commonOrderNumber, ")")),
                                            React.createElement("td", { "data-testid": "requested-net-discount" },
                                                React.createElement(PolishZloty, null, discount.requestedNetDiscount)),
                                            React.createElement("td", { "data-testid": "status" }, extraSaleDiscountStatusName(discount.status))));
                                    }))))))));
            }
        }()),
        React.createElement("div", { className: "form-controls-container" },
            React.createElement("button", { className: "btn btn-sm btn-confirm", onClick: submitApplication, "data-testid": "submitButton" }, "Wy\u015Blij do VCP")));
});
function percentage(value) {
    return (value + '\xa0%').replace('.', ',');
}
function producerDiscount(producerPercentDiscount, listPrice) {
    return producerPercentDiscount ? round(producerPercentDiscount * listPrice / 100) : 0;
    function round(number) {
        return Math.floor(number * 100) / 100;
    }
}
function FundingSelector(_a) {
    var funding = _a.funding, onFundingChanged = _a.onFundingChanged;
    var _b = useState([]), availableFundingForms = _b[0], setAvailableFundingForms = _b[1];
    var _c = useState(), selectedFundingFormId = _c[0], setSelectedFundingFormId = _c[1];
    var _d = useState([]), availableCfmProviders = _d[0], setAvailableCfmProviders = _d[1];
    var _e = useState(), selectedCfmProviderId = _e[0], setSelectedCfmProviderId = _e[1];
    var _f = useState([]), availableLeasingProviders = _f[0], setAvailableLeasingProviders = _f[1];
    var _g = useState(), selectedLeasingProviderId = _g[0], setSelectedLeasingProviderId = _g[1];
    useEffect(function () {
        var _a, _b, _c;
        if (funding) {
            setAvailableFundingForms(funding.fundingForms.available);
            setAvailableLeasingProviders(funding.leasingProviders.available);
            setAvailableCfmProviders(funding.cfmProviders.available);
            setSelectedFundingFormId((_a = funding.fundingForms.selected) === null || _a === void 0 ? void 0 : _a.id);
            setSelectedLeasingProviderId((_b = funding.leasingProviders.selected) === null || _b === void 0 ? void 0 : _b.id);
            setSelectedCfmProviderId((_c = funding.cfmProviders.selected) === null || _c === void 0 ? void 0 : _c.id);
        }
    }, [funding]);
    useEffect(function () {
        onFundingChanged({
            fundingForms: {
                available: availableFundingForms,
                selected: selectedFundingFormId
            },
            cfmProviders: {
                available: availableCfmProviders,
                selected: selectedCfmProviderId
            },
            leasingProviders: {
                available: availableLeasingProviders,
                selected: selectedLeasingProviderId
            }
        });
    }, [selectedFundingFormId, selectedCfmProviderId, selectedLeasingProviderId,
        availableFundingForms, availableCfmProviders, availableLeasingProviders,
        onFundingChanged]);
    return React.createElement("div", { className: "radio radio-selector", "data-testid": "fundingSelector" }, availableFundingForms.map(function (fundingForm) {
        return React.createElement("div", { className: "position", key: fundingForm.id },
            React.createElement("div", { className: "selection" },
                React.createElement("input", { id: fundingForm.id, type: "radio", checked: selectedFundingFormId === fundingForm.id, onChange: function () { return setSelectedFundingFormId(fundingForm.id); } }),
                React.createElement("label", { htmlFor: fundingForm.id, className: "position-label" }, fundingForm.name)),
            React.createElement("div", { className: "sub-selection" },
                fundingForm.id === selectedFundingFormId && fundingForm.id === 'CFM_FUNDING' ?
                    React.createElement(CfmProviderSelector, null) : null,
                fundingForm.id === selectedFundingFormId && fundingForm.id === 'DETAILED_LEASING' ?
                    React.createElement(LeasingProviderSelector, null) : null));
    }));
    function CfmProviderSelector() {
        return React.createElement("select", { "data-testid": "cfmProviderSelector", className: "form-control", onChange: function (event) { return setSelectedCfmProviderId(event.target.value); }, value: selectedCfmProviderId }, availableCfmProviders.map(function (availableCfmProvider) {
            return React.createElement("option", { value: availableCfmProvider.id, key: availableCfmProvider.id }, availableCfmProvider.name);
        }));
    }
    function LeasingProviderSelector() {
        return React.createElement("select", { "data-testid": "leasingProviderSelector", className: "form-control", onChange: function (event) { return setSelectedLeasingProviderId(event.target.value); }, value: selectedLeasingProviderId }, availableLeasingProviders.map(function (availableLeasingProvider) {
            return React.createElement("option", { value: availableLeasingProvider.id, key: availableLeasingProvider.id }, availableLeasingProvider.name);
        }));
    }
}
function ReasonSelector(_a) {
    var reasons = _a.reasons, carNetListPrice = _a.carNetListPrice;
    function totalRequestedNetDiscount() {
        return _.chain(reasons.available).filter(function ($) { return $.selected; }).map(function ($) { return $.requestedNetDiscount; }).filter(_.isFinite).sum().value();
    }
    return React.createElement("div", { className: "checkbox-selector col-lg-12", "data-testid": "applicationReasonSelector" },
        React.createElement("div", { className: "header" },
            React.createElement("div", { className: "header-position" },
                React.createElement("div", { className: "header-cell", style: { width: '40%' } },
                    React.createElement("label", { className: "control-label" }, "Pow\u00F3d")),
                React.createElement("div", { className: "header-cell", style: { width: '30%' } },
                    React.createElement("label", { className: "control-label" }, "Oczekiwana kwota rabatu netto")),
                React.createElement("div", { className: "header-cell", style: { width: '15%' } },
                    React.createElement("label", { className: "control-label" }, "Warto\u015B\u0107 rabatu (%)")))),
        reasons.available.map(function (applicationReason) {
            return React.createElement("div", { className: "position", key: applicationReason.id, "data-testid": "reason-".concat(applicationReason.id) },
                React.createElement("div", { className: "selection checkbox" },
                    React.createElement("input", { type: "checkbox", id: "reason-".concat(applicationReason.id), "data-testid": 'selectionCheckbox', checked: applicationReason.selected, onChange: function () { return reasons.onSelectionToggle(applicationReason.id); } }),
                    React.createElement("label", { htmlFor: "reason-".concat(applicationReason.id), className: "position-label" }, applicationReason.name)),
                React.createElement("div", { className: "selection selection-input" },
                    React.createElement("input", { type: "number", className: "form-control", style: { visibility: applicationReason.selected ? null : 'hidden' }, defaultValue: applicationReason.requestedNetDiscount, onChange: function (event) { return reasons.onRequestedNetDiscountChange(applicationReason.id, event.target.value && Number.parseInt(event.target.value)); }, "data-testid": "requestedNetDiscountInput" })),
                React.createElement("div", { className: "selection selection-value" },
                    React.createElement("span", { className: "form-control-static", style: { visibility: applicationReason.selected ? null : 'hidden' }, "data-testid": "discountPercent" }, roundedPercentage(applicationReason.requestedNetDiscount, carNetListPrice))));
        }),
        React.createElement("div", { className: "position" },
            React.createElement("div", { className: "selection selection-value" },
                React.createElement("label", { className: "position-label" }, "Suma")),
            React.createElement("div", { className: "selection selection-value" },
                React.createElement("span", { className: "form-control-static", "data-testid": "requestedNetDiscountTotal" },
                    React.createElement(PolishZloty, null, totalRequestedNetDiscount()))),
            React.createElement("div", { className: "selection selection-value" },
                React.createElement("span", { className: "form-control-static", "data-testid": "discountPercentTotal" }, roundedPercentage(totalRequestedNetDiscount(), carNetListPrice)))));
}
