import { shortPartyDescription } from '../../common/party';
import { modelName } from '../../common/models';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
function ratingScoreFace(ratingScore) {
    switch (ratingScore) {
        case 'GOOD':
            return 'far fa-smile fa-2x';
        case 'NEUTRAL':
            return 'far fa-meh fa-2x';
        case 'BAD':
            return 'far fa-frown fa-2x';
        default:
            return '';
    }
}
export function OfferEvent(_a) {
    var _b;
    var event = _a.event;
    return (React.createElement("div", { className: "well well-sm", style: { display: 'flex' } },
        React.createElement("div", null,
            React.createElement("span", null,
                event.payload.number,
                " dla ",
                shortPartyDescription(event.payload.party)),
            React.createElement("span", null,
                " na ",
                React.createElement("b", null, modelName(event.payload.modelId)),
                " ",
                event.payload.version,
                ", ",
                event.payload.drive,
                " za "),
            React.createElement("span", null,
                React.createElement("b", null,
                    React.createElement(CurrencyFormat, { value: event.payload.finalPrice, thousandSeparator: " ", displayType: "text", suffix: " z\u0142" })),
                " brutto"),
            React.createElement("span", null,
                " (cena katalogowa ",
                React.createElement(CurrencyFormat, { value: event.payload.listPrice, thousandSeparator: " ", displayType: "text", suffix: " z\u0142" }),
                ")"),
            ((_b = event.payload.sourceReference) === null || _b === void 0 ? void 0 : _b.commonOrderNumber) ? React.createElement("span", null,
                " na podstawie CON: ",
                event.payload.sourceReference.commonOrderNumber) : null,
            React.createElement("span", null, ".")),
        React.createElement("div", { style: { paddingLeft: '9px', position: 'relative', top: '-6px' } },
            React.createElement("span", { className: ratingScoreFace(event.payload.ratingScore) }))));
}
