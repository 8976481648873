var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import * as _ from 'lodash';
import Select, { components } from 'react-select';
var Option = function (props) {
    return (React.createElement(components.Option, __assign({}, props),
        React.createElement("div", { "data-testid": "option-".concat(props.selectProps.getOptionLabel(props.data)), className: 'option' },
            props.data.icon ?
                React.createElement("i", { className: "fas ".concat(props.data.icon) }) :
                React.createElement("input", { type: "checkbox", checked: props.isSelected, onChange: function () { }, style: { verticalAlign: 'text-top', margin: 0 } }),
            "\u00A0",
            props.children)));
};
var ValueContainer = function (props) {
    var placeholderOrLabels = props.children[0];
    return (React.createElement("div", { style: { overflow: 'auto', display: 'flex', padding: '0 4px', flexBasis: 0, flexGrow: 1 } },
        React.createElement("span", { style: { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' } }, placeholderOrLabels instanceof Array ?
            _.map(placeholderOrLabels, function ($) { return props.selectProps.getOptionLabel($.props.data); }).join(', ') :
            placeholderOrLabels),
        React.createElement("span", { style: { flexShrink: 0 } }, props.children.slice(props.children.length - 1))));
};
export default function Multiselect(props) {
    var SELECT_ALL_OPTION = { label: 'Zaznacz wszystkie', value: 'SELECT_ALL', icon: 'fa-check' };
    var DESELECT_ALL_OPTION = { label: 'Odznacz wszystkie', value: 'DESELECT_ALL', icon: 'fa-times' };
    function getOptions() {
        return [
            SELECT_ALL_OPTION,
            DESELECT_ALL_OPTION,
            {
                options: props.options
            }
        ];
    }
    function onChange(newValue, actionMeta) {
        var option = actionMeta.option;
        if (option.value === DESELECT_ALL_OPTION.value) {
            props.onChange([]);
        }
        else if (option.value === SELECT_ALL_OPTION.value) {
            props.onChange(props.options);
        }
        else {
            props.onChange(newValue);
        }
    }
    return (React.createElement(Select, __assign({}, props, { value: props.value, hideSelectedOptions: false, closeMenuOnSelect: false, onChange: function (newValue, actionMeta) { return onChange(newValue, actionMeta); }, options: getOptions(), placeholder: 'Wybierz', components: { Option: Option, ValueContainer: ValueContainer }, isClearable: false, isSearchable: false, getOptionLabel: function ($) { return props.getOptionLabel($) || $.label; }, getOptionValue: function ($) { return props.getOptionValue($) || $.value; }, isMulti: true, theme: function (theme) {
            return (__assign(__assign({}, theme), { borderRadius: 0, colors: __assign(__assign({}, theme.colors), { primary25: '#f5f5f5', primary: 'transparent', color: 'black' }) }));
        }, styles: {
            option: function (provided, state) { return (__assign(__assign({}, provided), { backgroundColor: state.isFocused ? '#f5f5f5' : undefined, color: '#333' })); },
            dropdownIndicator: function (provided) { return (__assign(__assign({}, provided), { padding: '6px' })); },
            control: function (provided) { return (__assign(__assign({}, provided), { '&:hover': {
                    borderColor: 'A'
                }, minHeight: '35px', borderColor: '#ccc' })); },
            container: function (provided) { return (__assign(__assign({}, provided), (props.style || []))); }
        } })));
}
