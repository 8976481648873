import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import { useExtraSaleDiscounts } from 'extra-sale-discounts/extraSaleDiscounts';
import { SubmissionForm } from 'extra-sale-discounts/submission-form/SubmissionForm.component';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './SubmissionForm.less';
export var NewSubmissionForm = withCancellation(function () {
    var extraSaleDiscounts = useExtraSaleDiscounts();
    var params = useParams();
    var _a = useState(null), application = _a[0], setApplication = _a[1];
    var clientId = params.clientId;
    var partyId = params.partyId ? decodeURIComponent(params.partyId) : undefined;
    useEffect(function () {
        (partyId ? extraSaleDiscounts.draftForParty(partyId) : extraSaleDiscounts.draftForClient(clientId))
            .then(function (data) { return setApplication(data); })
            .catch(function () { });
    }, []);
    return React.createElement(SubmissionForm, { application: application });
});
