import { AbstractTask } from './abstractTask.component';
import { polishTimestamp } from '../../../common/formatters/date';
export function ScheduledTestDrivesTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: polishTimestamp(task.from),
        action: 'Jazda próbna',
        description: [task.client, task.topic].filter(function ($) { return $; }).join(' '),
        redirectUrl: task.partyId ?
            "#/fleet-service/".concat(encodeURIComponent(task.partyId), "?scheduledContactId=").concat(task.id) :
            "#/customer-service//".concat(task.id, "?clearContext=true")
    });
}
