import { useEffect, useState } from 'react';
var LaddaState = /** @class */ (function () {
    function LaddaState() {
        this.value = 0;
        this.subscribers = [];
    }
    LaddaState.prototype.getValue = function () {
        return !!this.value;
    };
    LaddaState.prototype.add = function () {
        this.value++;
        this.setValue(!!this.value);
    };
    LaddaState.prototype.subtract = function () {
        this.value--;
        this.setValue(!!this.value);
    };
    LaddaState.prototype.setValue = function (newState) {
        var _this = this;
        if (this.value === newState) {
            return;
        }
        this.subscribers.forEach(function (subscriber) {
            subscriber(_this.value);
        });
    };
    LaddaState.prototype.subscribe = function (itemToSubscribe) {
        if (this.subscribers.indexOf(itemToSubscribe) > -1) {
            return;
        }
        this.subscribers.push(itemToSubscribe);
    };
    LaddaState.prototype.unsubscribe = function (itemToUnsubscribe) {
        this.subscribers = this.subscribers.filter(function (subscriber) { return subscriber !== itemToUnsubscribe; });
    };
    return LaddaState;
}());
var laddaState = new LaddaState();
export { laddaState };
export default function useLadda() {
    var _a = useState(), setState = _a[1];
    var state = laddaState.getValue();
    function reRender() {
        setState({});
    }
    useEffect(function () {
        laddaState.subscribe(reRender);
        return function () {
            laddaState.unsubscribe(reRender);
        };
    });
    return [state, setState];
}
