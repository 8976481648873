var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Modal } from 'react-bootstrap';
import * as _ from 'lodash';
var AbstractModal = /** @class */ (function (_super) {
    __extends(AbstractModal, _super);
    function AbstractModal(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = {
            modalManager: props.modalmanager,
            mounted: false,
            message: props.message,
            onCancel: props.cancelcallback,
            onConfirm: props.confirmcallback
        };
        _this.confirm = _this.confirm.bind(_this);
        _this.cancel = _this.cancel.bind(_this);
        return _this;
    }
    AbstractModal.prototype.componentDidMount = function () {
        this.setState(function (previousState) { return (__assign(__assign({}, previousState), { mounted: true })); });
    };
    AbstractModal.prototype.cancel = function () {
        this.state.onCancel();
        this.state.modalManager.remove(this);
    };
    AbstractModal.prototype.confirm = function () {
        this.state.onConfirm();
        this.state.modalManager.remove(this);
    };
    return AbstractModal;
}(React.Component));
var ConfirmationModal = /** @class */ (function (_super) {
    __extends(ConfirmationModal, _super);
    function ConfirmationModal(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.customFooter = props.footer;
        return _this;
    }
    ConfirmationModal.prototype.defaultFooter = function (onConfirm, onCancel) {
        return (React.createElement("div", null,
            React.createElement("button", { className: "btn btn-app btn-default", onClick: function () { return onCancel(); } }, "Nie"),
            "\u00A0",
            React.createElement("button", { className: "btn btn-app btn-confirm", onClick: function () { return onConfirm(); } }, "Tak")));
    };
    ConfirmationModal.prototype.render = function () {
        var _this = this;
        return (!this.state.mounted ? null :
            React.createElement(Modal, { show: true, onHide: function () { return _this.cancel(); } },
                React.createElement(Modal.Body, { className: "modal-body" },
                    React.createElement("h4", { dangerouslySetInnerHTML: { __html: this.state.message } })),
                React.createElement(Modal.Footer, { className: "modal-footer" }, this.customFooter
                    ? this.customFooter(this.confirm, this.cancel)
                    : this.defaultFooter(this.confirm, this.cancel))));
    };
    return ConfirmationModal;
}(AbstractModal));
var ErrorModal = /** @class */ (function (_super) {
    __extends(ErrorModal, _super);
    function ErrorModal(props, context) {
        return _super.call(this, props, context) || this;
    }
    ErrorModal.prototype.defaultFooter = function () {
        var _this = this;
        return function (onConfirm) { return (React.createElement("div", null,
            React.createElement("button", { className: "btn btn-app btn-confirm", onClick: function () { return onConfirm(_this); } }, "OK"))); };
    };
    ErrorModal.prototype.render = function () {
        var _this = this;
        return (!this.state.mounted ? null :
            React.createElement(Modal, { show: true, onHide: function () { return _this.confirm(); } },
                React.createElement(Modal.Header, null,
                    React.createElement(Modal.Title, { className: "modal-title" }, "B\u0141\u0104D")),
                React.createElement(Modal.Body, { className: "modal-body" },
                    React.createElement("div", { style: { whiteSpace: 'pre-line' }, dangerouslySetInnerHTML: { __html: this.state.message } })),
                React.createElement(Modal.Footer, { className: "modal-footer" }, this.defaultFooter()(this.confirm))));
    };
    return ErrorModal;
}(AbstractModal));
var ModalManager = /** @class */ (function () {
    function ModalManager() {
        this.modals = [];
    }
    ModalManager.prototype.register = function (modalContainer) {
        this.modalContainer = modalContainer;
        this.updateModalContainer();
    };
    ModalManager.prototype.add = function (modal, props) {
        this.modals.push(React.createElement(modal, props));
        this.updateModalContainer();
    };
    ModalManager.prototype.remove = function (modal) {
        _.remove(this.modals, function ($) { return $.props.uid === modal.props.uid; });
        this.updateModalContainer();
    };
    ModalManager.prototype.clear = function () {
        this.modals = [];
        this.modalContainer = null;
    };
    ModalManager.prototype.updateModalContainer = function () {
        if (this.modalContainer) {
            this.modalContainer.setModals(this.modals);
        }
    };
    return ModalManager;
}());
export var modalManager = new ModalManager();
var MessageBox = /** @class */ (function () {
    function MessageBox() {
    }
    MessageBox.confirmation = function (message, customFooter) {
        return new Promise((function (resolve, reject) {
            modalManager.add(ConfirmationModal, {
                uid: 'modal-' + Math.random(),
                modalmanager: modalManager,
                message: message,
                footer: customFooter,
                cancelcallback: function () { reject(); },
                confirmcallback: function () { resolve(); }
            });
        }));
    };
    MessageBox.error = function (message) {
        return new Promise((function (resolve) {
            modalManager.add(ErrorModal, {
                uid: 'modal-' + Math.random(),
                modalmanager: modalManager,
                message: message,
                confirmcallback: function () { resolve(); }
            });
        }));
    };
    return MessageBox;
}());
export { MessageBox };
var ModalContainer = /** @class */ (function (_super) {
    __extends(ModalContainer, _super);
    function ModalContainer(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = {
            mounted: false,
            modals: []
        };
        return _this;
    }
    ModalContainer.prototype.componentDidMount = function () {
        this.setState(function (previousState) { return (__assign(__assign({}, previousState), { mounted: true })); });
        modalManager.register(this);
    };
    ModalContainer.prototype.setModals = function (modals) {
        this.setState(function (previousState) { return (__assign(__assign({}, previousState), { modals: modals })); });
    };
    ModalContainer.prototype.render = function () {
        return (!this.state.mounted ? null :
            React.createElement("div", null, this.state.modals.map(function ($) { return React.createElement("div", { key: $.props.uid }, $); })));
    };
    return ModalContainer;
}(React.Component));
export { ModalContainer };
