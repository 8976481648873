import moment from 'moment';
function eventTypeDescription(type, location, isSpecialOffer) {
    if (isSpecialOffer === void 0) { isSpecialOffer = false; }
    switch (type) {
        case 'MEETING':
            return location === 'OUT_OF_SHOWROOM' ? 'Spotkanie poza salonem' : 'Spotkanie w\xa0salonie';
        case 'CAR_HANDOVER':
            return 'Wydanie samochodu';
        case 'CALL':
            return 'Telefon';
        case 'INTERNET':
            return 'Internet';
        case 'TEST_DRIVE':
            return 'Jazda próbna';
        case 'DEMO_CAR_HANDOVER':
            return 'Wydanie demo';
        case 'DEMO_CAR_RETURN':
            return 'Zwrot demo';
        case 'RETURNING_CLIENT':
            return 'Wracający klient';
        case 'OTHER':
            return 'Inne';
        case 'NEW_CLIENT':
            return 'Nowy klient';
        case 'SERVICE_VISIT':
            return 'Wizyta w\xa0serwisie';
        case 'SEASONAL_MAINTENANCE':
            return 'Przegląd';
        case 'OFFER':
            return 'Oferta';
        case 'SUGGESTED_OFFER':
            return 'Sugerowana oferta';
        case 'ORDER':
            return 'Zamówienie';
        case 'HANDOVER':
            return 'Wydanie';
        case 'ORDER_WITHDRAWAL':
            return 'Wycofanie zamówienia';
        case 'CONCESSION_APPLICATION':
            return isSpecialOffer ? 'Oferta specjalna' : 'Rabat VCP';
        case 'EXTRA_SALE_DISCOUNT_APPLICATION':
            return 'Rabat DRS';
        case 'PHONE_NUMBER_CAMPAIGN':
            return 'Kampania telefoniczna';
        case 'SMS_CAMPAIGN':
            return 'Kampania SMS';
        case 'EMAIL_CAMPAIGN':
            return 'Kampania e-mail';
        default:
            return type;
    }
}
function timelineTimestamp(timestamp) {
    return moment(timestamp).format('DD.MM.YYYY\u00a0H:mm');
}
export { eventTypeDescription, timelineTimestamp };
