import { PolishZloty } from 'common/formatters/PolishZloty';
import { shortModelName } from 'common/models';
import { extraSaleDiscountStatusName } from 'extra-sale-discounts/extraSaleDiscountStatus';
import React from 'react';
export function PartyExtraSaleDiscounts(props) {
    return React.createElement("div", { className: "form-horizontal col-lg-12" },
        React.createElement("div", { className: "form-inline" },
            React.createElement("div", { className: "form-group col-lg-12" },
                React.createElement("div", { className: "col-lg-12" },
                    React.createElement("table", { className: "table table-striped" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "Dealer"),
                                React.createElement("th", null, "Samoch\u00F3d"),
                                React.createElement("th", null, "Kwota rabatu netto"),
                                React.createElement("th", null, "Status"))),
                        React.createElement("tbody", { "data-testid": "extra-sale-discounts-for-party-rows" }, props.partyExtraSaleDiscounts.map(function (discount) {
                            return (React.createElement("tr", { key: discount.id, "data-testid": 'extra-sale-discounts-for-party-row-' + discount.id },
                                React.createElement("td", { "data-testid": "organization-name" }, discount.dealerName),
                                React.createElement("td", { "data-testid": "model-id" }, "".concat(shortModelName(discount.car.modelId), " (CON ").concat(discount.car.commonOrderNumber, ")")),
                                React.createElement("td", { "data-testid": "requested-net-discount" },
                                    React.createElement(PolishZloty, null, discount.requestedNetDiscount)),
                                React.createElement("td", { "data-testid": "status" }, extraSaleDiscountStatusName(discount.status))));
                        })))))));
}
