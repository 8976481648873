import { AbstractTask } from './abstractTask.component';
export function OrderWithdrawalApplicationsToAcceptTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Wycofaj zamówienie',
        description: "".concat(task.dealerName, ", ").concat(task.dealerOrder),
        redirectUrl: "#/order-withdrawal/".concat(task.orderId)
    });
}
