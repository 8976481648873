import { AbstractTask } from './abstractTask.component';
import { modelName } from '../../../common/models';
export function AssistanceStartDateToFillTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Uruchom Assistance',
        description: "".concat(task.representativeName, " ").concat(modelName(task.model)),
        redirectUrl: "#/orders/".concat(task.orderId, "/assistance")
    });
}
