import React, { useContext } from 'react';
import { DashboardContext } from '../../Dashboard.component';
export function AbstractTask(_a) {
    var type = _a.type, pickUpInfo = _a.pickUpInfo, action = _a.action, description = _a.description, redirectUrl = _a.redirectUrl;
    var dashboardContext = useContext(DashboardContext);
    var tabIndex = 1;
    var goTo = function (redirectUrl) {
        if (clickable()) {
            window.location.href = redirectUrl;
        }
    };
    var clickable = function () {
        return !dashboardContext.previewMode && redirectUrl;
    };
    return (React.createElement("div", { "data-testid": type, role: "button", tabIndex: tabIndex, onClick: function () { return goTo(redirectUrl); }, className: 'well well-sm task-component' + (clickable() ? ' clickable' : '') },
        React.createElement("table", null,
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", { "data-testid": "pickUpInfo" }, pickUpInfo),
                    React.createElement("td", { "data-testid": "action" }, action),
                    React.createElement("td", { "data-testid": "description" }, description))))));
}
