import { AbstractTask } from './abstractTask.component';
import { polishTimestamp } from '../../../common/formatters/date';
import { modelName } from '../../../common/models';
export function ScheduledCarHandoversTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: polishTimestamp(task.from),
        action: 'Wydanie samochodu',
        description: [task.client, modelName(task.model)].filter(function ($) { return $; }).join(', '),
        redirectUrl: "#/orders/".concat(task.id, "/car-handover")
    });
}
