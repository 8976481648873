import Axios from 'axios';
import AuthToken from 'common/authToken';
import { MessageBox } from 'common/message-box/messageBox';
import { useCancelTokenSourceContext } from 'common/requests-cancellation/requestsCancellation';
import { laddaState } from 'http/ladda';
import React from 'react';
function createAxiosInstance() {
    var axiosInstance = Axios.create({});
    var authorizationInterceptor = function (request) {
        var authToken = AuthToken.get();
        if (authToken) {
            request.headers['AuthToken'] = authToken;
        }
        return request;
    };
    var errorInterceptor = function (error) {
        if (!error.response) {
            return Promise.reject(error);
        }
        if (error.response.status === 401) {
            if (error.response.data.errorCode === 'password-expired') {
                window.location.href = '#/user-password-change';
            }
            else {
                window.location.href = '#/login';
            }
        }
        else {
            window.dispatchEvent(new ErrorEvent('error', {
                error: error.response.data.errorCode,
                message: error.response.data.description
            }));
        }
        return Promise.reject(error);
    };
    var applicationNeedsUpdateInterceptor = function (response) {
        var oldApplicationFingerprint = AuthToken.decodeApplicationFingerprint();
        var authToken = response.headers.authtoken;
        if (authToken) {
            AuthToken.set(authToken);
        }
        notifyThatApplicationNeedsUpdateIfChanged(oldApplicationFingerprint);
        return response;
    };
    axiosInstance.interceptors.request.use(authorizationInterceptor);
    axiosInstance.interceptors.response.use(applicationNeedsUpdateInterceptor, errorInterceptor);
    axiosInstance.interceptors.request.use(function ($) {
        laddaState.add();
        return $;
    });
    axiosInstance.interceptors.response.use(function ($) {
        laddaState.subtract();
        return $;
    }, function ($) {
        laddaState.subtract();
        return Promise.reject($);
    });
    function notifyThatApplicationNeedsUpdateIfChanged(oldApplicationFingerprint) {
        var newApplicationFingerprint = AuthToken.decodeApplicationFingerprint();
        if (oldApplicationFingerprint && newApplicationFingerprint && oldApplicationFingerprint !== newApplicationFingerprint) {
            showApplicationNeedsUpdateModal();
        }
    }
    function showApplicationNeedsUpdateModal() {
        MessageBox.confirmation('Dostępna jest nowa wersja DOL.<br/>Aktualizacja zajmie kilka sekund. Nie ma wpływu na inne aplikacje.', function (confirm, cancel) { return (React.createElement("div", null,
            React.createElement("div", { className: "pull-left" },
                React.createElement("button", { className: "btn btn-app btn-default", onClick: function () { return cancel(); } }, "Przypomnij mi za chwil\u0119")),
            React.createElement("div", { className: "pull-right" },
                React.createElement("button", { className: "btn btn-app btn-confirm", onClick: function () { return confirm(); } }, "Zaktualizuj")))); }).then(function () { return window.location.reload(); }, function () {
            setTimeout(function () { return showApplicationNeedsUpdateModal(); }, 5 * 60 * 1000);
        });
    }
    return axiosInstance;
}
var axiosInstance = createAxiosInstance();
export var http = axiosInstance;
export function useHttp() {
    var http = createAxiosInstance();
    var cancelTokenSource = useCancelTokenSourceContext();
    http.defaults.cancelToken = cancelTokenSource.token;
    return http;
}
