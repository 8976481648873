import { polishDate } from 'common/formatters/date';
import React from 'react';
export function ActivePartyVcpDiscounts(props) {
    return React.createElement("div", { className: "form-horizontal col-lg-12" },
        React.createElement("div", { className: "form-inline" },
            React.createElement("div", { className: "form-group col-lg-12" },
                React.createElement("div", { className: "col-lg-12" },
                    React.createElement("table", { className: "table table-striped" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "Numer"),
                                React.createElement("th", null, "Dealer"),
                                React.createElement("th", null, "Rabat wa\u017Cny do"))),
                        React.createElement("tbody", null, props.partyActiveConcessions.map(function (concession) {
                            return React.createElement("tr", { key: concession.id },
                                React.createElement("td", null, concession.number),
                                React.createElement("td", null, concession.dealerName),
                                React.createElement("td", null, polishDate(concession.validTo)));
                        })))))));
}
