import { useHttp } from 'http/httpService';
export function useUserCards() {
    var http = useHttp();
    return {
        get: function (login) {
            return http.get("/api/users/".concat(login, "?card"))
                .then(function (response) { return response.data; });
        }
    };
}
