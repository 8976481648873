import { AbstractTask } from './abstractTask.component';
export function PendingConcessionSettlementDocumentationsTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Uzupełnij dokumenty audytowe',
        description: task.partyName,
        redirectUrl: "#/orders/".concat(task.documentationId, "/concessions")
    });
}
