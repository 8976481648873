var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { MessageBox } from 'common/message-box/messageBox';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import { useHttp } from 'http/httpService';
import React from 'react';
import './FileUploadForm.less';
export function useFiles() {
    var http = useHttp();
    function loadLocation(location) {
        return http.get('/api' + location, { responseType: 'blob' });
    }
    function openFile(response, fileName) {
        var url = window.URL.createObjectURL(new Blob([response.data]));
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(url);
    }
    return {
        upload: function (file) {
            return __awaiter(this, void 0, Promise, function () {
                function sendFile(data) {
                    return http.post('/api/files', data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                }
                var formData, response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            formData = new FormData();
                            formData.append('file', file);
                            return [4 /*yield*/, sendFile(formData)];
                        case 1:
                            response = _a.sent();
                            return [2 /*return*/, ({
                                    name: file.name,
                                    location: response.headers.location
                                })];
                    }
                });
            });
        },
        delete: function (fileLocation) {
            return http.delete("/api".concat(fileLocation));
        },
        openLocation: function (file) {
            loadLocation(file.location).then(function (response) { return openFile(response, file.name); }).catch(function () { });
        }
    };
}
export var FileUploadForm = withCancellation(function (props) {
    var filesRepository = useFiles();
    function onUpload(event) {
        var files = event.target.files;
        if (props.multi === true) {
            Promise.all(Array.from(files).map(filesRepository.upload)).then(function (results) {
                props.onChange(__spreadArray(__spreadArray([], props.value, true), results, true));
            }).catch(function () { });
        }
        if (props.multi === false) {
            filesRepository.upload(files[0]).then(function (result) {
                props.onChange(__spreadArray(__spreadArray([], props.value, true), [
                    result
                ], false));
            }).catch(function () { });
        }
    }
    function onRemove(fileToRemove) {
        MessageBox.confirmation("Czy na pewno usun\u0105\u0107 za\u0142\u0105cznik \"".concat(fileToRemove.name, "\""))
            .then(function () {
            filesRepository.delete(fileToRemove.location).then(function () {
                props.onChange(props.value.filter(function ($) { return $.location !== fileToRemove.location; }));
            }).catch(function () { });
        });
    }
    return React.createElement("div", { className: "file-upload-form" },
        React.createElement("form", null,
            React.createElement("table", { className: "table table-condensed body-without-header" },
                React.createElement("tbody", null,
                    props.value.map(function (file) { return React.createElement("tr", { key: file.location },
                        React.createElement("td", null,
                            React.createElement("span", { className: "fas fa-minus remove-attachment-btn", onClick: function () { onRemove(file); }, "data-testid": 'remove-' + file.location })),
                        React.createElement("td", null,
                            React.createElement("a", { role: "button", tabIndex: 0, onClick: function () { return filesRepository.openLocation(file); } }, file.name))); }),
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            React.createElement("label", { htmlFor: "file-upload-input", className: "fas fa-plus add-attachment-btn" })),
                        React.createElement("td", null,
                            React.createElement("input", { id: "file-upload-input", type: "file", multiple: props.multi, onChange: onUpload, className: "file-upload-input", "data-testid": "fileInput", value: "" })))))));
});
