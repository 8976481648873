import { AbstractTask } from './abstractTask.component';
export function ServiceRequestsToHandleTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Odpowiedz',
        description: "Zapytania serwisowe: ".concat(task.count),
        redirectUrl: '#/service-call-center'
    });
}
