import React from 'react';
import UserContext from 'common/userContext';
import moment from 'moment';
export var TableList = function (_a) {
    var nip = _a.nip, name = _a.name, status = _a.status, startOfActivity = _a.startOfActivity, onClick = _a.onClick, onChangeStatus = _a.onChangeStatus;
    var isCustomerServiceVCP = UserContext.hasAnyRole('VCP_CUSTOMER_SERVICE');
    return (React.createElement("div", { className: "table-list" },
        React.createElement("div", { role: "button", "data-testid": "parts-wholesalers-list-item", tabIndex: 0, onClick: onClick, className: "well well-sm clickable party-row ".concat(status ? 'suspended' : '') },
            React.createElement("table", { className: "table wholesalers-table" },
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", { className: "table-nip-id-col", "data-testid": "table-cell-nip-id" }, nip),
                        React.createElement("td", { className: "table-name-col", "data-testid": "table-cell-name" }, name),
                        React.createElement("td", { className: "table-status-col", "data-testid": "table-cell-status" }, status ? 'Zawieszony' : 'Aktywny'),
                        React.createElement("td", { className: "table-activity-from-col", "data-testid": "table-cell-beginning-activity" }, moment(startOfActivity).format('YYYY-MM-DD')))))),
        isCustomerServiceVCP ? (React.createElement("div", { className: "table-suspension-checkbox" },
            React.createElement("input", { type: "checkbox", id: "suspension", checked: status, onChange: onChangeStatus }))) : null));
};
