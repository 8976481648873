import { phoneNumber } from '../common/phoneNumber';
import React from 'react';
export function UserCard(_a) {
    var user = _a.user;
    function shouldDataPreview() {
        if (user.name) {
            return !user.name.toLowerCase().includes('webcon');
        }
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "col-lg-12 form-control-static" }, user.organizationName),
        React.createElement("div", { className: "col-lg-12 form-inline", style: { display: 'flex', justifyContent: 'space-between' } },
            React.createElement("span", { className: "form-control-static" }, user.name),
            React.createElement("span", { className: "form-control-static" }, shouldDataPreview() ? user.email : ''),
            React.createElement("span", { className: "form-control-static" }, shouldDataPreview() ? phoneNumber(user.phoneNumber) : '')));
}
