import { useHttp } from 'http/httpService';
export function useNewPartWholesaler() {
    var http = useHttp();
    return {
        add: function (wholesalerNumber, numberType) {
            return http
                .post('/api/parts-wholesale-parties-whitelist/add-new-party', { value: wholesalerNumber, type: numberType })
                .then(function (response) { return response.data; });
        },
    };
}
