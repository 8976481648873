import { AbstractTask } from './abstractTask.component';
export function CorrectingRegistrationApplicationsTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Skoryguj rejestrację',
        description: "".concat(task.dealerOrder, " ").concat(task.dealerName),
        redirectUrl: "#/sale-cars/".concat(task.commonOrderNumber)
    });
}
