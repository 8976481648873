// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#react .extra-sale-discount-application-data .form-horizontal {
  margin-top: 10px;
  margin-bottom: 10px;
}
#react .extra-sale-discount-application-data .no-right-padding {
  padding-right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/extra-sale-discounts/applicationData.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;EACA,mBAAA;AAAJ;AAHA;EAOI,gBAAA;AADJ","sourcesContent":[".extra-sale-discount-application-data {\n  .form-horizontal {\n    margin-top: 10px;\n    margin-bottom: 10px;\n  }\n\n  .no-right-padding {\n    padding-right: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
