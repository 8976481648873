import { eventTypeDescription, timelineTimestamp } from 'assistant-timeline/events/events';
import { fullName } from 'common/formatters/fullName';
import { withCancellation } from 'common/requests-cancellation/requestsCancellation';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import '../../assistant-timeline/AssistantTimeline.less';
import { useSalesScheduledContacts } from './salesScheduledContacts';
export var ScheduledContacts = withCancellation(function (props) {
    var salesScheduledContacts = useSalesScheduledContacts();
    var _a = useState('ALL'), contactsFilter = _a[0], setContactsFilter = _a[1];
    var _b = useState([]), rawScheduledContacts = _b[0], setRawScheduledContacts = _b[1];
    var _c = useState([]), filteredScheduledContacts = _c[0], setFilteredScheduledContacts = _c[1];
    useEffect(function () {
        salesScheduledContacts.loadFor(props.assistantId)
            .then(function (response) { return setRawScheduledContacts(_.orderBy(response.data, 'from', 'asc')); })
            .catch(function () { });
    }, [props.assistantId]);
    useEffect(function () {
        filter();
        function filter() {
            switch (contactsFilter) {
                case 'OFFER':
                    setFilteredScheduledContacts(rawScheduledContacts.filter(function (event) { return _.includes(['OFFER', 'SUGGESTED_OFFER'], event.type); }));
                    break;
                case 'CAR':
                    setFilteredScheduledContacts(rawScheduledContacts.filter(function (event) { return _.includes(['ORDER', 'HANDOVER', 'CAR_HANDOVER'], event.type); }));
                    break;
                case 'CONCESSION_APPLICATION':
                    setFilteredScheduledContacts(rawScheduledContacts.filter(function (event) { return _.includes(['CONCESSION_APPLICATION'], event.type); }));
                    break;
                case 'CONTACT':
                    setFilteredScheduledContacts(rawScheduledContacts.filter(function (event) { return _.includes(['CALL', 'MEETING', 'OTHER'], event.type); }));
                    break;
                default:
                    setFilteredScheduledContacts(rawScheduledContacts);
            }
        }
    }, [rawScheduledContacts, contactsFilter]);
    return (React.createElement("article", { className: "assistant-timeline", "data-testid": "scheduled-contacts" },
        React.createElement(ToggleButtonGroup, { className: "filter-controls", type: "radio", name: "scheduled-contacts-filter", justified: true, value: contactsFilter, onChange: setContactsFilter },
            React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "all-scheduled-contacts-filter", value: "ALL" }, "Wszystkie"),
            React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "offers-scheduled-contacts-filter", value: "OFFER" }, "Oferty"),
            React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "car-scheduled-contacts-filter", value: "CAR" }, "Samochody"),
            React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "concession-application-scheduled-contacts-filter", value: "CONCESSION_APPLICATION" }, "Rabaty VCP"),
            React.createElement(ToggleButton, { className: "filter-toggle-button", "data-testid": "contact-scheduled-contacts-filter", value: "CONTACT" }, "Zdarzenia")),
        React.createElement("div", null,
            React.createElement("table", { className: "table" },
                React.createElement("colgroup", null,
                    React.createElement("col", { className: "col-lg-2" }),
                    React.createElement("col", { className: "col-lg-2" }),
                    React.createElement("col", { className: "col-lg-8" })),
                React.createElement("tbody", null, filteredScheduledContacts.map(function (scheduledContact) {
                    return (React.createElement("tr", { style: { wordBreak: 'break-word' }, key: scheduledContact.id, "data-testid": "event-record-".concat(scheduledContact.id) },
                        React.createElement("td", { style: { verticalAlign: 'middle' } },
                            React.createElement("span", null, timelineTimestamp(scheduledContact.from))),
                        React.createElement("td", { style: { verticalAlign: 'middle', textAlign: 'center' } }, eventTypeDescription(scheduledContact.type, scheduledContact.location)),
                        React.createElement("td", null,
                            React.createElement("div", { className: "well well-sm" },
                                "Zaplanowa\u0142 ",
                                scheduledContact.assistantName,
                                " do ",
                                timelineTimestamp(scheduledContact.to),
                                " z ",
                                fullName(scheduledContact.contactInfo),
                                ". ",
                                scheduledContact.topic))));
                }))))));
});
