import { polishTimestamp } from 'common/formatters/date';
import { modelName } from 'common/models';
import { shortPartyDescription } from 'common/party';
import { AbstractTask } from './abstractTask.component';
export function ExtraSaleDiscountApplicationsToGiveAccountingOpinionTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: 'Pilne',
        action: 'Zaopiniuj wniosek o rabat DRS',
        description: "Wniosek z dnia ".concat(polishTimestamp(task.submissionTimestamp), ", ").concat(task.dealer, ", ").concat(shortPartyDescription(task.party), ", ").concat(task.car ? modelName(task.car.modelId) : '', ", ").concat(task.car ? task.car.commonOrderNumber : ''),
        redirectUrl: "#/extra-sale-discounts/opinion/ACCOUNTING/".concat(task.id)
    });
}
