import { useHttp } from 'http/httpService';
export function useExtraSaleDiscounts() {
    var http = useHttp();
    return {
        draftForClient: function (clientId) {
            return http.post('/api/extra-sale-discounts/draft', { clientId: clientId })
                .then(function (response) { return response.data; });
        },
        draftForParty: function (partyId) {
            return http.post('/api/extra-sale-discounts/draft', { partyId: partyId })
                .then(function (response) { return response.data; });
        },
        load: function (applicationId) {
            return http.get("/api/extra-sale-discounts/".concat(applicationId))
                .then(function (response) { return response.data; });
        },
        submit: function (application) {
            return http.post('/api/extra-sale-discounts', application);
        },
        correct: function (id, application) {
            return http.put("/api/extra-sale-discounts/".concat(id, "/correct"), application);
        },
        supplementCarNetListPrice: function (id, carNetListPrice, attachments) {
            return http.put("/api/extra-sale-discounts/".concat(id, "/supplement-car-net-list-price"), {
                carNetListPrice: carNetListPrice,
                attachments: attachments
            });
        },
        listForIdentificationNumber: function (identificationNumber) {
            return http.get("/api/extra-sale-discounts?forIdentificationNumber=".concat(identificationNumber))
                .then(function (response) { return response.data; });
        },
        search: function (phrase) {
            return http.get("/api/extra-sale-discounts?phrase=".concat(phrase))
                .then(function (response) { return response.data; });
        },
        acceptTentatively: function (applicationId, acceptedApplicationReasons, comment) {
            return http.post("/api/extra-sale-discounts/".concat(applicationId, "/accept-tentatively"), {
                acceptedApplicationReasons: acceptedApplicationReasons,
                comment: comment
            });
        },
        accept: function (applicationId, acceptedApplicationReasons, comment) {
            return http.post("/api/extra-sale-discounts/".concat(applicationId, "/accept"), {
                acceptedApplicationReasons: acceptedApplicationReasons,
                comment: comment
            });
        },
        return: function (applicationId, acceptedApplicationReasons, comment) {
            return http.post("/api/extra-sale-discounts/".concat(applicationId, "/return"), {
                acceptedApplicationReasons: acceptedApplicationReasons,
                comment: comment
            });
        },
        reject: function (applicationId, comment) {
            return http.post("/api/extra-sale-discounts/".concat(applicationId, "/reject"), {
                comment: comment
            });
        },
        givePositiveSalesOpinion: function (applicationId, comment) {
            return http.post("/api/extra-sale-discounts/".concat(applicationId, "/give-positive-sales-opinion"), {
                comment: comment
            });
        },
        giveNegativeSalesOpinion: function (applicationId, comment) {
            return http.post("/api/extra-sale-discounts/".concat(applicationId, "/give-negative-sales-opinion"), {
                comment: comment
            });
        },
        givePositiveAccountingOpinion: function (applicationId, comment) {
            return http.post("/api/extra-sale-discounts/".concat(applicationId, "/give-positive-accounting-opinion"), {
                comment: comment
            });
        },
        giveNegativeAccountingOpinion: function (applicationId, comment) {
            return http.post("/api/extra-sale-discounts/".concat(applicationId, "/give-negative-accounting-opinion"), {
                comment: comment
            });
        }
    };
}
