import { FileUploadForm } from 'common/components/FileUploadForm';
import React from 'react';
export function CarNetListPriceSupplement(props) {
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-group col-lg-12" },
                React.createElement("label", { id: "invoiceNetListPrice", className: "control-label col-lg-3" }, "Cena katalogowa netto"),
                React.createElement("div", { "aria-labelledby": "invoiceNetListPrice", className: "col-lg-3" },
                    React.createElement("input", { type: "number", className: "form-control", value: props.carNetListPrice, onChange: function (event) { return props.setCarNetListPrice(Number.parseFloat(event.target.value)); } })))),
        React.createElement("div", { className: "form-horizontal col-lg-12" },
            React.createElement("div", { className: "form-group col-lg-12" },
                React.createElement("label", { id: "attachments", className: "col-lg-3 control-label" }, "Za\u0142\u0105czniki"),
                React.createElement("div", { "aria-labelledby": "attachments", className: "col-lg-5" },
                    React.createElement(FileUploadForm, { multi: true, value: props.attachments, onChange: props.setAttachments })))));
}
