import { AbstractTask } from './abstractTask.component';
import { polishTimestamp } from '../../../common/formatters/date';
import { phoneNumber } from '../../../common/phoneNumber';
export function LeadsAssistantAssignmentTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: polishTimestamp(task.contactUntil),
        action: 'Przydziel lead',
        description: [task.name, phoneNumber(task.phoneNumber), task.email, task.comment].filter(function ($) { return !!$; }).join(' '),
        redirectUrl: "#/lead-assistant-assignment?leadId=".concat(task.id)
    });
}
