import React from 'react';
export function ContactEvent(_a) {
    var event = _a.event;
    return (React.createElement("div", { className: "well well-sm" },
        React.createElement("div", null,
            event.payload.clientName ? React.createElement("span", null,
                event.payload.clientName,
                ". ") : null,
            event.payload.note)));
}
