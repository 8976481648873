export function extraSaleDiscountStatus(status) {
    switch (status) {
        case 'SUBMITTED':
            return 'Wysłany';
        case 'TENTATIVELY_ACCEPTED':
            return 'Wstępnie zaakceptowany';
        case 'ACCEPTED':
            return 'Zaakceptowany';
        case 'RETURNED':
            return 'Do skorygowania';
        default:
            return status;
    }
}
