import { polishTimestamp } from '../../../common/formatters/date';
import { AbstractTask } from './abstractTask.component';
export function ScheduledMeetingsTask(_a) {
    var task = _a.task;
    return AbstractTask({
        type: task.type,
        pickUpInfo: polishTimestamp(task.from),
        action: task.location === 'OUT_OF_SHOWROOM' ? 'Spotkanie poza salonem' : 'Spotkanie w\xa0salonie',
        description: [(task.party ? "".concat(task.party, " (").concat(task.client, ")") : task.client), task.topic].filter(function ($) { return $; }).join(' '),
        redirectUrl: task.partyId ?
            "#/fleet-service/".concat(encodeURIComponent(task.partyId), "?scheduledContactId=").concat(task.id) :
            "#/customer-service//".concat(task.id, "?clearContext=true")
    });
}
