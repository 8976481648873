import { polishDate } from 'common/formatters/date';
import { discountName } from 'common/formatters/discountName';
import { fundingName } from 'common/formatters/fundingName';
import { PolishZloty } from 'common/formatters/PolishZloty';
import { settlementStatus } from 'common/formatters/settlementStatus';
import React from 'react';
export function VcpDiscountSettlement(props) {
    return React.createElement(React.Fragment, null, props.settlement && React.createElement("div", { className: "form-horizontal col-lg-12" },
        React.createElement("div", { className: "form-inline" },
            React.createElement("div", { className: "form-group col-lg-12" },
                React.createElement("div", { className: "col-lg-12" },
                    React.createElement("table", { "data-testid": "settlement", className: "table table-striped table-hover" },
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { rowSpan: 2 }, "Data zam\u00F3wienia"),
                                React.createElement("th", { rowSpan: 2 }, "Oferta specjalna/Grupa rabatowa VCP"),
                                React.createElement("th", { style: { width: '12%' }, rowSpan: 2 }, "Cena katalogowa netto"),
                                React.createElement("th", { rowSpan: 2 }, "Rabat klienta netto"),
                                React.createElement("th", { style: { width: '12%' }, colSpan: 2, className: "text-center" }, "Rabat VCP"),
                                React.createElement("th", { rowSpan: 2 }, "Finansowanie"),
                                React.createElement("th", { rowSpan: 2 }, "Status")),
                            React.createElement("tr", null,
                                React.createElement("th", null, "%"),
                                React.createElement("th", null, "kwota netto"))),
                        React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement("td", { "data-testid": "orderDate" }, polishDate(props.settlement.orderDate)),
                                React.createElement("td", { "data-testid": "discountGroup" }, discountName(props.settlement.discount)),
                                React.createElement("td", { "data-testid": "listPrice" },
                                    React.createElement(PolishZloty, null, props.settlement.listPrice)),
                                React.createElement("td", { "data-testid": "discount" },
                                    React.createElement(PolishZloty, null, props.settlement.listPrice - props.settlement.finalPrice)),
                                React.createElement("td", { "data-testid": "producerPercentDiscount" }, percentage(props.settlement.producerPercentDiscount)),
                                React.createElement("td", { "data-testid": "producerDiscount" },
                                    React.createElement(PolishZloty, null, producerDiscount(props.settlement.producerPercentDiscount, props.settlement.listPrice))),
                                React.createElement("td", { "data-testid": "funding" }, fundingName(props.settlement.funding)),
                                React.createElement("td", { "data-testid": "status" }, settlementStatus(props.settlement.status))))))))));
}
function percentage(value) {
    return (value + '\xa0%').replace('.', ',');
}
function producerDiscount(producerPercentDiscount, listPrice) {
    return producerPercentDiscount ? round(producerPercentDiscount * listPrice / 100) : 0;
    function round(number) {
        return Math.floor(number * 100) / 100;
    }
}
